// eslint-disable-next-line no-underscore-dangle
const _defaultPrimaryColor = '#171A88';

export const DEFAULT_THEME = {
  // bar
  barText: "#18181A",
  barBackground: "#fff",
  barBorder: _defaultPrimaryColor,
  barButton: _defaultPrimaryColor,
  barButtonText: '#fff',
  // window tab
  windowBackground: '#fff',
  windowTabItem: '#f1f1f1',
  windowTabItemText: _defaultPrimaryColor,
  windowHoverTabItem: '#f1f1f1',
  windowHoverTabItemText: '#000',
  windowSelectedTabItem: '#fff',
  windowSelectedTabItemText: '#000',
  // window content
  windowContentBackground: '#f1f1f1',
  windowContentTitles: '#18181A',
  windowContentTexts: '#18181A',
  windowContentActivatedSwitchs: 'blue',
  windowContentDeactivateditchs: 'yellow',
  windowContentBtnSave: '',
  windowContentTextBtnSave: '',
  windowContentFooterLine: '#0005',
  windowContentCookieTableBtn: _defaultPrimaryColor,
  windowContentCookieTableHeader: _defaultPrimaryColor,
  windowContentCookieTableHeaderText: '#fff',
  windowContentCookieTable: '#fff',
  windowContentCookieTableText: _defaultPrimaryColor,
};
