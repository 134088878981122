<template>
  <div class="q-lgpd-settings" :style="styleMap">
    <div class="q-lgpd-settings__window">

      <div class="q-lgpd-settings__header">

        <div class="q-lgpd-settings__logo-wrapper">
          <slot name="logo">
            <img class="q-lgpd-settings__default-logo" src="../../assets/imgs/logo-qualicorp.svg">
          </slot>
        </div>

        <q-lgpd-settings-tabs
          v-model="tabIndex"
          class="q-lgpd-settings__tabs"
          :tabs="tabs"
        />
      </div>

      <div class="q-lgpd-settings__content">
        <q-lgpd-settings-contents
          :content-index="tabIndex"
          :switch-colors="switchColors"
          :button-save-config="buttonSaveConfig"
          :cookies="cookies"
          @save-settings="$emit('save-settings', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import QLgpdSettingsTabs from './components/Tabs';
// eslint-disable-next-line import/extensions
import QLgpdSettingsContents from './components/Contents';
// eslint-disable-next-line import/extensions
import TabIcons from './constants/TabIcons.js';

export default {
  components: {
    QLgpdSettingsTabs,
    QLgpdSettingsContents,
  },

  props: {
    theme: {
      type: Object,
      required: true,
    },
    cookies: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tabIndex: 0,
      tabs: [
        { text: 'SOBRE SUA PRIVACIDADE', svgIcon: TabIcons.PRIVACITY },
        { text: 'COOKIES ESSENCIAIS', svgIcon: TabIcons.ESSENTIAL },
        { text: 'COOKIES DE TERCEIROS', svgIcon: TabIcons.THIRD },
        { text: 'COOKIES DE FUNCIONALIDADE', svgIcon: TabIcons.FUNCIONALITY },
        { text: 'POLÍTICA DE COOKIES', svgIcon: TabIcons.POLICY },
      ],
    };
  },

  computed: {
    styleMap() {
      return {
        '--window-background-color': this.theme.windowBackground,
        '--window-tab-item-color': this.theme.windowTabItem,
        '--window-tab-item-text-color': this.theme.windowTabItemText,
        '--window-hover-tab-item-color': this.theme.windowHoverTabItem,
        '--window-hover-tab-item-text-color': this.theme.windowHoverTabItemText,
        '--window-selected-tab-item-color': this.theme.windowSelectedTabItem,
        '--window-selected-tab-item-text-color': this.theme.windowSelectedTabItemText,

        '--window-content-background-color': this.theme.windowContentBackground,
        '--window-content-titles-color': this.theme.windowContentTitles,
        '--window-content-texts-color': this.theme.windowContentTexts,

        '--window-content-cookie-table-btn-color': this.theme.windowContentCookieTableBtn,
        '--window-content-cookie-table-header-color': this.theme.windowContentCookieTableHeader,
        '--window-content-cookie-table-header-text-color': this.theme.windowContentCookieTableHeaderText,
        '--window-content-cookie-table-color': this.theme.windowContentCookieTable,
        '--window-content-cookie-table-text-color': this.theme.windowContentCookieTableText,
        '--window-content-footer-line-color': this.theme.windowContentFooterLine,
      };
    },

    switchColors() {
      return {
        activated: this.theme.windowContentEnabledSwitchs,
        deactivated: this.theme.windowContentDisabledSwitchs,
      };
    },

    buttonSaveConfig() {
      return {
        text: this.textButtonSave,
        textColor: this.theme.windowContentDisabledSwitchs,
        backgroundColor: this.theme.windowContentEnabledSwitchs,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
  @import "../../scss/_variables.scss";

  @media (min-width: 768px) and (max-height: 700px) {
     .q-lgpd-settings {
      position: fixed;
      z-index: 1000002;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
      background-color: #0005;
      font-family: var(--font-family);

      &__window {
        display: flex;
        width: 80vw;
        height: 100vh;
        min-height: 600px;
        transform: scale(.7);
        background-color: var(--window-background-color);
        border-radius: 10px;
      }

      &__header {
        min-width: 420px;
        padding: 30px;
      }

      &__logo-wrapper {
        height: 70px;
        margin-bottom: 30px;
      }

      &__default-logo {
        display: block;
        height: 140%;
        width: 100%;
        margin: auto;
      }

      &__content {
        background-color: var(--window-content-background-color);
        border-radius: 10px;
      }
    }
  }

  @media #{$breakpoint-desktop} {
    .q-lgpd-settings {
      position: fixed;
      z-index: 1000002;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
      background-color: #0005;
      font-family: var(--font-family);

      &__window {
        display: flex;
        width: 80vw;
        height: 100vh;
        max-width: 1170px;
        max-height: 600px;
        background-color: var(--window-background-color);
        border-radius: 10px;
      }

      &__header {
        min-width: 420px;
        padding: 30px;
      }

      &__logo-wrapper {
        height: 70px;
        margin-bottom: 30px;
      }

      &__default-logo {
        display: block;
        height: 140%;
        width: 100%;
        margin: auto;
      }

      &__content {
        background-color: var(--window-content-background-color);
        border-radius: 10px;
      }
    }
  }

  @media #{$breakpoint-tablet} {
    .q-lgpd-settings {
      position: fixed;
      z-index: 1002;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
      background-color: #0005;
      font-family: var(--font-family);

      &__window {
        display: flex;
        width: 90vw;
        height: 100vh;
        max-width: 800px;
        max-height: 460px;
        background-color: var(--window-background-color);
        border-radius: 10px;
      }

      &__header {
        min-width: 350px;
        padding: 30px 30px;
      }

      &__logo-wrapper {
        height: 60px;
        margin-bottom: 30px;
      }

      &__default-logo {
        display: block;
        height: 100%;
        margin: auto;
      }

      &__content {
        background-color: var(--window-content-background-color);
        flex-grow: 1;
        border-radius: 10px;
      }
    }
  }

  @media #{$breakpoint-mobile} {
    .q-lgpd-settings {
      position: fixed;
      z-index: 9999;
      display: flex;
      justify-content: center;
      width: 100vw;
      height: 100vh;
      background-color: #0005;
      font-family: var(--font-family);

      &__window {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 100vh;
        background-color: var(--window-background-color);;
      }

      &__header {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 30px;
      }

      &__tabs {
        margin: 0 auto;
      }

      &__logo-wrapper {
        width: 100%;
        margin-bottom: 30px;
        padding: 0 40px;
      }

      &__default-logo {
        max-height: 100px;
        width: 100%;
        margin: auto;
      }

      &__content {
        background-color: var(--window-content-background-color);
        border-radius: 10px;
        flex-grow: 1;
      }
    }
  }
</style>
