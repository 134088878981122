var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "q-lgpd-settings", style: _vm.styleMap }, [
    _c("div", { staticClass: "q-lgpd-settings__window" }, [
      _c(
        "div",
        { staticClass: "q-lgpd-settings__header" },
        [
          _c(
            "div",
            { staticClass: "q-lgpd-settings__logo-wrapper" },
            [
              _vm._t("logo", function () {
                return [
                  _c("img", {
                    staticClass: "q-lgpd-settings__default-logo",
                    attrs: {
                      src: require("../../assets/imgs/logo-qualicorp.svg"),
                    },
                  }),
                ]
              }),
            ],
            2
          ),
          _c("q-lgpd-settings-tabs", {
            staticClass: "q-lgpd-settings__tabs",
            attrs: { tabs: _vm.tabs },
            model: {
              value: _vm.tabIndex,
              callback: function ($$v) {
                _vm.tabIndex = $$v
              },
              expression: "tabIndex",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "q-lgpd-settings__content" },
        [
          _c("q-lgpd-settings-contents", {
            attrs: {
              "content-index": _vm.tabIndex,
              "switch-colors": _vm.switchColors,
              "button-save-config": _vm.buttonSaveConfig,
              cookies: _vm.cookies,
            },
            on: {
              "save-settings": function ($event) {
                return _vm.$emit("save-settings", $event)
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }