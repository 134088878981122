var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "q-lgpd-settings-tabs" },
    _vm._l(_vm.tabs, function (tab, index) {
      return _c(
        "li",
        { key: index, staticClass: "q-lgpd-settings-tabs__item-wrapper" },
        [
          _c("q-lgpd-settings-tabs-item", {
            staticClass: "q-lgpd-settings-tabs__item",
            attrs: {
              text: tab.text,
              svgIcon: tab.svgIcon,
              activated: index === _vm.tabIndex,
            },
            on: {
              click: function ($event) {
                _vm.tabIndex = index
              },
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }