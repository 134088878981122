import api from '@/services/api';
import usuarioService from '@/services/usuarioService';
import store from '../store';
import { Capacitor } from '@capacitor/core'

const authRouter = async (to, from, next) => {
  let token = localStorage.getItem(`${process.env.VUE_APP_NAME_AUTH}`);
  const isLoggedIn = store.getters['auth/isLoggedIn'];
  const hasValidUser = store.getters['cadastroCorretor/hasUser'];
  let query = {};
  const requireAuth = to.matched.some((record) => record.meta.requireAuth);
  const requireValidUser = to.matched.some((record) => record.meta.requireValidUser);
  // token inserido na loja
  const match = document.cookie.match(new RegExp('(^| )auth_corretor_qvenda=([^;]+)'));
  if (match && !token) {
    // match � um array, o valor do cookie/token sempre vem no index 2
    const { 2: tokenLoja } = match;
    localStorage.setItem(process.env.VUE_APP_NAME_AUTH, tokenLoja);
    if (!Capacitor.isNativePlatform()) {
      api.defaults.headers.common.auth = `Bearer ${tokenLoja}`;
    }
    token = tokenLoja;
  }
  if (requireAuth) {
    if (!token) {
      if (!from || from.name === 'login') return;
      if (to && to.query && to.query.redirect) {
        query = { path: to.path, params: to.params };
      }
      next({ name: 'login', query });
    } else {
      try {
        const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_ACESSO_API_KEY } };
        const response = await api.post(`acessos/token/validar`, {}, config);
        const { data } = response.data;
        if (!Capacitor.isNativePlatform()) {
          api.defaults.headers.common.auth = token;
        }
        if (data) {
          store.dispatch('user/setUserCpf', data.corretor.cpf);
          next();
        } else {
          localStorage.removeItem(`${process.env.VUE_APP_NAME_AUTH}`);
          if (!from || from.name === 'login') return;
          if (to && to.query && to.query.redirect) {
            query = { path: to.path, params: to.params };
          }
          next({ name: 'login', query });
        }
      } catch (error) {
        localStorage.removeItem(`${process.env.VUE_APP_NAME_AUTH}`);
        if (to && to.query && to.query.redirect) {
          query = { path: to.path, params: to.params };
        }
        next({ name: 'login', query });
      }
    }
  } else next();
};

export default authRouter;
