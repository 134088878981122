<template>
  <v-snackbar v-model="snackbar" :bottom="true" :color="color" :timeout="timeout" style="z-index: 999; position: fixed;" :absolute="true">
    <div v-if="icon.name" class="d-flex align-center">
      <v-icon :color="icon.color" class="mr-4">{{ icon.name }}</v-icon>
      <span class="mt-1">{{ message }}</span>
    </div>
    <template v-else>
      <div class="d-flex align-center">
        {{ message }}
        <v-spacer></v-spacer>
        <v-btn small darck text @click="close()">Fechar</v-btn>
      </div>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      snackbar: false,
      timeout: 3000,
      color: "success",
      resolve: null,
      reject: null,
      message: "",
      icon: {
        name: "",
        color: "white",
      },
    };
  },
  methods: {
    open({ color, message }) {
      /* eslint-disable */
      const iconColor = color === "error" ? "error" : "white";
      const iconName = color === "warning" ? "mdi-alert" : color === "success" ? "mdi-check-circle" : color === "error" ? "mdi-close-circle" : "mdi-information";

      this.message = message;
      this.color = color === "error" ? "black" : color;

      this.icon = {
        name: iconName,
        color: iconColor,
      };

      this.snackbar = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
      /* eslint-enable */
    },
    close() {
      this.resolve(false);
      this.snackbar = false;
    },
  },
};
</script>

<style></style>
