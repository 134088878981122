<template>
  <the-wrapper-auto-height>
    <div>
      <Cookie
      :cookies="{
        essential: [
         { name: 'moove_gdpr', source: 'Qualicorp', type: 'Essencial', duration: '365 dias' },
        ],
         funcionality: [
          { name: '_ga', source: 'Qualicorp', type: 'Funcionalidade', duration: '720 dias'},
          { name: '_gid', source: 'Qualicorp', type: 'Funcionalidade', duration: '24 horas'},
          { name: '_gcl_au', source: 'Qualicorp', type: 'Funcionalidade', duration: '24 horas'},
        ]
      }"
      :theme="theme = {
        windowContentCookieTableHeader: '#171a88',
        windowContentCookieTableHeaderText: '#fff',
      }"
      :persistInCookie="true"
      :qualiCookieName="'moove_gdpr'"
      ></Cookie>
    </div>
    <LoadingInit />
    <v-app>
      <the-confirm-dialog ref="confirmdialog"></the-confirm-dialog>
      <the-snack-bar ref="snackBar"></the-snack-bar>
      <div
        :class="['auto', {}]"
        :style="{
          backgroundColor: !isLoggedIn
            ? $store.getters.getColor('primary')
            : '',
        }"
      >
        <v-fade-transition leave-absolute hide-on-leave>
          <router-view></router-view>
        </v-fade-transition>
      </div>
    </v-app>
    <v-dialog v-model="$store.getters.getTermoUso.dialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="headline">
          <div class="modal-title">
            Os termos de Uso e Política de Privacidade foram atualizados
          </div>
        </v-card-title>

        <v-card-text class="card-visualizar-termos">
          <div
            class="blue--text cursor-pointer mt-3 pr-1"
            @click="abrirTermos(true)"
          >
            <v-icon>mdi-chevron-right</v-icon>
            Visualizar termos de uso
          </div>
        </v-card-text>

        <v-card-text>
          <v-checkbox
            :value="$store.getters.getTermoUso.check"
            label="Concordo com os termos de uso e política de privacidade."
            color="primary"
            @change="abrirTermos(false)"
          ></v-checkbox>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="orange darken-1"
            class="cursor-pointer"
            text
            @click="desisteTermo()"
            :disabled="authLoading"
          >
            Desistir
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class="cursor-pointer"
            text
            @click="aceitar()"
            :disabled="!$store.getters.getTermoUso.check"
            :loading="authLoading"
          >
            continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </the-wrapper-auto-height>
</template>

<script>
/* eslint-disable */
import LoadingInit from "@/components/LoadingInit.vue";
import TheWrapperAutoHeight from "@/components/TheWrapperAutoHeight.vue";
import TheSnackBar from "@/components/TheSnackBar.vue";
import TheConfirmDialog from "@/components/TheConfirmDialog.vue";
import Cookie from "@/components/QualiLgpd/index.vue";
import { mapGetters, mapActions } from "vuex";
import service from "@/services/whiteLabelService";
import usuarioService from "@/services/usuarioService";

export default {
  name: "App",
  components: {
    LoadingInit,
    Cookie,
    TheWrapperAutoHeight,
    TheSnackBar,
    TheConfirmDialog,
  },
  data() {
    return {
      loadingStyle: true,
      isErrorStyle: false,
      authLoading: false,
      corretor: {},
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
    }),
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
      setIsMobile: "user/setCheckIsMobile",
    }),
    setComponentsInGlobalRoot() {
      this.$root.$confirmdialog = this.$refs.confirmdialog;
      this.$root.$snackBar = this.$refs.snackBar;
    },
    async setStyle() {
      try {
        this.$store.commit("SET_LOADING_INIT", true);
        this.isErrorStyle = false;
        let url = window.location.href;
        const urlArr = url.split("/#/");
        if (urlArr && urlArr[0]) {
          [url] = urlArr;
        }
        if (url.includes("localhost") || url.includes("192.")) {
          const env = process.env.NODE_ENV;
          if (env === "dev") url = "https://celuladigitaldev.qualicorp.com.br/QVendaCorretor";
          else if (env === "homol") url = "https://celuladigitalhm.qualicorp.com.br/QVendaCorretor";
          else if (env === "preprod") url = "https://celuladigitalpreprod.qualicorp.com.br/QVendaCorretor";
          else url = "https://qualivendas.qualicorp.com.br";
        }

        const whiteLabelData = await service.getVariaveis(url);
        const link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href =
          whiteLabelData.favicon || `${process.env.BASE_URL}/favicon.ico`;
        document.getElementsByTagName("head")[0].appendChild(link);

        document.title = whiteLabelData.appName;

        this.$vuetify.theme.themes.light.primary =
          whiteLabelData.colors.primary;

        this.$store.commit("SET_BRAND", whiteLabelData);
        this.$store.commit("SET_LOADING_INIT", false);
      } catch (error) {
        this.$store.commit("SET_LOADING_INIT", false);
        this.isErrorStyle = true;
      }
    },
    desisteTermo() {
      this.$store.commit("SET_DIALOG_TERMO", false);
    },
    abrirTermos(abrir = false) {
      if (!abrir) { this.$store.commit("SET_CHECK_TERMO", !this.$store.getters.getTermoUso.check); }
      const url = `${process.env.VUE_APP_BASE_GTQUALI_URL}gtquali/pdf/termos_uso.pdf?api-key=${process.env.VUE_APP_GTQUALI_API_KEY}`;

      if (!this.$store.getters.getTermoUso.jaVisualizado && (abrir || this.$store.getters.getTermoUso.check)) {
        window.open(
          url,
          "_blank",
        );
        this.$store.commit("SET_VISUALIZADO_TERMO", true);
      } else if (this.$store.getters.getTermoUso.jaVisualizado && abrir === true) {
        window.open(
          url,
          "_blank",
        );
      }
    },
    async aceitar() {
      if (this.corretor) {
        this.authLoading = true;
        try {
          await usuarioService.aceitaTermos({ cpf: this.corretor.cpf });
          // this.submit();
          this.$store.commit("SET_CALLBACK_TERMO", 'submit');
          this.$store.commit("SET_DIALOG_TERMO", false);
        } catch (error) {
          this.authLoading = false;
          this.$root.$snackBar.open({ color: "error", message: error.message });
        }
      }
    },

  },
  async created() {
    await this.setStyle();
    this.setIsMobile();
    /**
     *  cria um interceptador de requisicao para a instancia axios, caso o retrono seja um 401 o usuario é deslogado
     * */
    this.$http.interceptors.response.use(
      async (response) => {
        if (response && response.config.url.indexOf("corretores/aceita-termos-portal") > -1) {
          return response;
        }
        if (
          response
          && response.data
          && response.data.data
        && response.data.data.token
        && response.config.url.indexOf("corretores/login") > -1
        ) {
          this.auth = response.data.data.token;
        }

        if (response && response.config.url.indexOf("corretores/login") > -1) {
          if (response.data.data.concordoTermosQualiVendas !== true) {
            this.corretor = response.data.data;
            this.$store.commit("SET_TERMO_USO", {
              check: false,
              jaVisualizado: false,
              dialog: true,
              callback: '',
            });
            this.$store.commit("SET_LOADING_GLOBAL", false);
            return false;
          }
        }

        return response;
      },
      async (error) => {
        if (error.message === "Network Error") {
          this.$root.$snackBar.open({
            color: "error",
            message: "Sem conexão com a internet",
          });
        } else if (
          error.response &&
          error.response.status === 401 &&
          this.isLoggedIn
        ) {
          await this.logout();
          this.$root.$snackBar.open({
            color: "error",
            message: "Login expirado",
          });
          let query = {};
          if (this.$route.query && this.$route.query.redirect) {
            query = { path: this.$route.path, params: this.$route.params };
          }
          this.$router.push({ name: "login", query });

          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );
  },
  async mounted() {
    this.setComponentsInGlobalRoot();
  },
};
</script>

<style lang="scss" scoped>
.auto {
  display: flex;
  height: 100%;
  flex: 1;
  background: #fff;
  transition: background ease 0.5s;
}
</style>
