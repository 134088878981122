function validate(value) {
  const reg = /^[0-9]{2} [0-9]?[0-9]{4}-[0-9]{4}$/;
  if (!reg.test(value)) return false;
  const phone = value.slice(3, value.length);
  for (let i = 0; i < 10; i++) {
    if (phone === `${i}${i}${i}${i}-${i}${i}${i}${i}` || phone === `${i}${i}${i}${i}${i}-${i}${i}${i}${i}`) {
      return false;
    }
  }
  return true;
}

export default validate;
