export default {
  setTratamentos({ commit }, payload) {
    commit('SET_TRATAMENTOS', payload);
  },
  setIndicacao({ commit }, payload) {
    commit('SET_INDICACAO', payload);
  },
  setFiltros({ commit }, payload) {
    commit('SET_FILTROS', payload);
  },
  setStatusLeads({ commit }, payload) {
    commit('SET_STATUSLEADS', payload);
  },
  setCleanFilter({ commit }, payload) {
    commit('CLEAN_FILTER', payload);
  },
  setVinculoPropostaManualToLead({ commit }, payload) {
    commit('SET_VINCULO_PROPOSTA_LEAD', payload);
  },
  setPlantaoLeads({ commit }, payload) {
    commit('SET_PLANTAOLEADS', payload);
  },
};
