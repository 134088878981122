<template>
  <q-lgpd-settings-contents-base
    class="q-lgpd-settings-contents-privacity"
    :title="computedTitle"
    @click-save="$emit('click-save')"
  >
    <slot>
      <p>
        Estes cookies permitem que o site forneça mais funcionalidade e personalização.
        Podem ser definidos por nós ou por fornecedores terceiros cujos serviços adicionamos às nossas páginas.
        O objetivo destes cookies é fornecer-lhe uma experiência mais personalizada em nosso site com base nas suas visitas e seleções anteriores.
        Se não permitir estes cookies, todos ou alguns destes serviços poderão não funcionar corretamente.
      </p>
      <p>Mais informações, acesso o nosso <a href="https://qualicorp.com.br/avisodeprivacidade/" target="_blank">Aviso de Privacidade</a>.</p>
    </slot>
  </q-lgpd-settings-contents-base>
</template>

<script>
// eslint-disable-next-line import/extensions
import QLgpdSettingsContentsBase from '../Base';
// eslint-disable-next-line import/extensions
import DefaultTitles from '../../../../constants/DefaultTitles.js';

export default {
  components: {
    QLgpdSettingsContentsBase,
  },

  props: {
    title: String,
  },

  computed: {
    computedTitle() {
      return this.title || DefaultTitles.POLICY;
    },
  },
};
</script>
