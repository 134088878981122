var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "q-lgpd-settings-tabs-item",
      class: _vm.classMap,
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("div", {
        staticClass: "q-lgpd-settings-tabs-item__icon-wrapper",
        domProps: { innerHTML: _vm._s(_vm.svgIcon) },
      }),
      _c("div", { staticClass: "q-lgpd-settings-tabs-item__text" }, [
        _vm._v(_vm._s(_vm.text)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }