const state = {
  idLead: null,
  tipoTaxaAngariacao: null,
  idFilial: null,
  proposta: {},
  corretora: {},
  operadora: {},
  plano: {},
  dataVigencia: null,
  titular: {},
  dependentes: [],
  pagamento: {},
  dadosParaReembolso: {},
  documentos: {},
  blnContinuar: {},
  responsavelLegal: {},
  valorTotalProposta: 0.0,
  vigencia: {},
  pendencias: [],
  adendo: [],
  produtosOpcionais: [],
  dadosComplementaresFiliacao: null,
  corretoraRepasse: null,
  inicializada: null,
  etapaBeforeReset: null,
  valorTaxaAdesao: null,
  justificativaTaxaAngariacao: null,
};

export default state;
