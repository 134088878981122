// eslint-disable-next-line import/no-extraneous-dependencies
import { Response } from 'miragejs';

const corretorInternoJaCadastrado = {
  id: '1',
  nome: 'Angieli Rodrigues',
  cpf: '54547852022',
  contato: {
    id: '1',
    email: 'angielir@qualicorp.com.br',
    telefones: [
      {
        id: '1',
        tipo: 'celular',
        numero: '11957930736',
        preferencial: true,
      },
    ],
  },
  dataNascimento: '30/11/1990',
  tipoCorretor: {
    valor: '1',
    descricao: 'Interno',
  },
  endereco: {
    id: '1',
    cep: '05884070',
    uf: 'SP',
    cidade: 'São Paulo',
    bairro: 'Jd do Estado',
    logradouro: 'Rua ivaldo',
    numero: '36',
    complemento: '',
  },
  corretoras: [
    {
      id: '1',
      idSinf: '2',
      idFilial: '3',
      nomeFantasia: 'Angieli Rodrigues',
    },
  ],
};

const corretorInternoNovo = {
  id: '9',
  nome: 'Márcio Ponte',
  cpf: '36726459076',
  dataNascimento: '30/11/1980',
  tipoCorretor: {
    valor: '1',
    descricao: 'Interno',
  },
};

const corretorExternoJaCadastrado = {
  id: '1',
  nome: 'Danilo Indelicato',
  cpf: '64350194010',
  contato: {
    id: '1',
    email: 'daniloi@qualicorp.com.br',
    telefones: [
      {
        id: '1',
        tipo: 'celular',
        numero: '11957930736',
        preferencial: true,
      },
    ],
  },
  dataNascimento: '30/11/1990',
  tipoCorretor: {
    valor: '2',
    descricao: 'Externo',
  },
  endereco: {
    id: '1',
    cep: '05884070',
    uf: 'SP',
    cidade: 'São Paulo',
    bairro: 'Jd do Estado',
    logradouro: 'Rua ivaldo',
    numero: '36',
    complemento: '',
  },
  corretoras: [
    {
      id: '1',
      idSinf: '2',
      idFilial: '3',
      nomeFantasia: 'Brasil Seg Corretora',
    },
    {
      id: '2',
      idSinf: '3',
      idFilial: '4',
      nomeFantasia: 'JRB Corretora',
    },
  ],
};

const corretorExternoNovo = {
  id: '19',
  nome: 'Guilherme Santana',
  cpf: '00255200080',
  dataNascimento: '30/11/1990',
  tipoCorretor: {
    valor: '2',
    descricao: 'Externo',
  },
};

export const buscarCorretor = (schema, request) => {
  const { cpf } = JSON.parse(request.requestBody);

  const newCpf = cpf.replace(/[^\d]+/g, '');

  switch (newCpf) {
  case '54547852022': // interno ja cadastrado
    return corretorInternoJaCadastrado;

  case '64350194010': // externo ja cadastrado

    return corretorExternoJaCadastrado;

  case '36726459076': // interno novo

    return corretorInternoNovo;

  case '00255200080': // externo novo

    return corretorExternoNovo;

  default:
    return new Response(404, {}, { errors: 'CPF não encontrado' });
  }
};

export const login = (schema, request) => {
  const { email, senha } = JSON.parse(request.requestBody);

  if (email === 'corretorinterno@qualicorp.com.br' && senha === 'quali01') {
    // interno já cadastrado
    return {
      token: { token: '123456', refreshToken: '123456789' },
      usuario: corretorInternoJaCadastrado,
    };
  } if (email === 'corretorexterno@qualicorp.com.br'
    && senha === 'quali01') {
    // externo já cadastrado
    return {
      token: { token: '123456', refreshToken: '123456789' },
      usuario: corretorExternoJaCadastrado,
    };
  } if (email === 'corretorinternonovo@qualicorp.com.br'
    && senha === 'quali01') {
    // interno novo
    return {
      token: { token: '123456', refreshToken: '123456789' },
      usuario: corretorInternoNovo,
    };
  } if (email === 'corretorexternonovo@qualicorp.com.br'
    && senha === 'quali01') {
    // externo novo
    return {
      token: { token: '123456', refreshToken: '123456789' },
      usuario: corretorExternoNovo,
    };
  }
  return new Response(404, {}, { errors: 'Email ou senha inválidos' });
};
