var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      staticStyle: { "z-index": "999", position: "fixed" },
      attrs: {
        bottom: true,
        color: _vm.color,
        timeout: _vm.timeout,
        absolute: true,
      },
      model: {
        value: _vm.snackbar,
        callback: function ($$v) {
          _vm.snackbar = $$v
        },
        expression: "snackbar",
      },
    },
    [
      _vm.icon.name
        ? _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "v-icon",
                { staticClass: "mr-4", attrs: { color: _vm.icon.color } },
                [_vm._v(_vm._s(_vm.icon.name))]
              ),
              _c("span", { staticClass: "mt-1" }, [
                _vm._v(_vm._s(_vm.message)),
              ]),
            ],
            1
          )
        : [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _vm._v(" " + _vm._s(_vm.message) + " "),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: { small: "", darck: "", text: "" },
                    on: {
                      click: function ($event) {
                        return _vm.close()
                      },
                    },
                  },
                  [_vm._v("Fechar")]
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }