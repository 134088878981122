import propostaService from '@/services/propostaService';

export default {
  async setStatusPagamento({ commit, rootState }) {    
    const { nrProposta } = rootState.cadastroProposta.proposta;
    const res = await propostaService.getStatusPagamentoTaxa(nrProposta);
    return res;
  },

  async enviarLinkPagamento({ commit, rootState }) {
    console.log(rootState.cadastroProposta.proposta);
    const { guid } = rootState.cadastroProposta.proposta;
    const res = await propostaService.enviarLinkPagamento(guid);
    return res;
  },
};
