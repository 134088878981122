var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-lgpd-settings-contents-base",
    {
      staticClass: "q-lgpd-settings-contents-privacity",
      attrs: { title: _vm.computedTitle },
      on: {
        "click-save": function ($event) {
          return _vm.$emit("click-save")
        },
      },
    },
    [
      _vm._t("default", function () {
        return [
          _c("p", [
            _vm._v(
              " Ao visitar um site, com a ajuda dos cookies, arquivos de internet que armazenam temporariamente seus acessos, entendemos seu comportamento dentro daquele ambiente. A ideia é que essas informações sejam utilizadas a seu favor, principalmente, para oferecer uma experiência mais personalizada, com produtos e serviços que façam a diferença para você. No entanto, em respeito ao seu direito de privacidade, é possível permitir ou não certos tipos de cookies, que estão disponíveis para você alterar em nossas configurações, a qualquer momento. "
            ),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }