import Vue from 'vue';
import VueRouter from 'vue-router';
import authRouter from '@/utils/auth';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  scrollBehavior: () => {
    window.scrollTo(0, 0);
  },
});

router.beforeEach(async (to, from, next) => {
  authRouter(to, from, next);
});

export default router;
