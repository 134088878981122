export default {
  LOGOUT(state) {
    state.token = null;
  },
  REQUEST(state, { loading = false }) {
    state.loading = loading;
  },
  SUCCESS(state, { loading }) {
    state.loading = loading;
  },
  ERROR(state, { loading }) {
    state.loading = loading;
  },
  SET_DATA(state, payload) {
    state.token = { ...payload };
  },
};
