var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.disbabled && _vm.visibility,
          expression: "!disbabled && visibility",
        },
      ],
      staticClass: "quali-lgpd",
      style: _vm.styleMap,
    },
    [
      _vm.settingsVisibility && !_vm.disbabled
        ? _c("q-lgpd-settings", {
            staticClass: "quali-lgpd__settings",
            attrs: { cookies: _vm.cookies, theme: _vm.currentTheme },
            on: { "save-settings": _vm.acceptSomeCookies },
          })
        : _vm._e(),
      _vm.barVisibility && !_vm.disbabled && !_vm.rodandoMobile
        ? _c("q-lgpd-bar", {
            staticClass: "quali-lgpd__bar",
            attrs: { "top-bar": _vm.topBar, theme: _vm.currentTheme },
            on: {
              "click-settings": _vm.showSettings,
              "click-ok": _vm.acceptAllCookies,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "text",
                  fn: function () {
                    return [_vm._t("barText")]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }