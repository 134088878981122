var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "q-lgpd-settings-contents" },
    [
      _c(
        "q-lgpd-settings-contents-privacity",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.contentIndex === _vm.sections.PRIVACITY,
              expression: "contentIndex === sections.PRIVACITY",
            },
          ],
          attrs: {
            "switch-colors": _vm.switchColors,
            "button-save-config": _vm.buttonSaveConfig,
          },
          on: { "click-save": _vm.evClickSave },
        },
        [_vm._t("privacity")],
        2
      ),
      _c(
        "q-lgpd-settings-contents-essential",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.contentIndex === _vm.sections.ESSENTIAL,
              expression: "contentIndex === sections.ESSENTIAL",
            },
          ],
          attrs: {
            "switch-colors": _vm.switchColors,
            "button-save-config": _vm.buttonSaveConfig,
            cookies: _vm.cookies.essential,
          },
          on: { "click-save": _vm.evClickSave },
        },
        [_vm._t("essential")],
        2
      ),
      _c(
        "q-lgpd-settings-contents-third",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.contentIndex === _vm.sections.THIRD,
              expression: "contentIndex === sections.THIRD",
            },
          ],
          attrs: {
            "switch-colors": _vm.switchColors,
            "button-save-config": _vm.buttonSaveConfig,
            cookies: _vm.cookies.thirdParty,
          },
          on: {
            "click-save": _vm.evClickSave,
            "update-enabled": function ($event) {
              return _vm.updateSettings("third", $event)
            },
          },
        },
        [_vm._t("third")],
        2
      ),
      _c(
        "q-lgpd-settings-contents-funcionality",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.contentIndex === _vm.sections.FUNCIONALITY,
              expression: "contentIndex === sections.FUNCIONALITY",
            },
          ],
          attrs: {
            "switch-colors": _vm.switchColors,
            "button-save-config": _vm.buttonSaveConfig,
            cookies: _vm.cookies.funcionality,
          },
          on: {
            "click-save": _vm.evClickSave,
            "update-enabled": function ($event) {
              return _vm.updateSettings("funcionality", $event)
            },
          },
        },
        [_vm._t("funcionality")],
        2
      ),
      _c(
        "q-lgpd-settings-contents-policy",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.contentIndex === _vm.sections.POLICY,
              expression: "contentIndex === sections.POLICY",
            },
          ],
          attrs: {
            "switch-colors": _vm.switchColors,
            "button-save-config": _vm.buttonSaveConfig,
          },
          on: { "click-save": _vm.evClickSave },
        },
        [_vm._t("policy")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }