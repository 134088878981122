import api from '@/services/api';
import AppError from '@/utils/appError';
import filters from '@/filters';
import apiNoAuth from '@/services/apiNoAuth';
import { Capacitor } from '@capacitor/core'

const resource = 'corretores';
const resourceToken = 'token';

const login = async ({ email, senha }) => {
  try {
    const sistema = process.env.VUE_APP_SISTEMA_LOGIN;
    const body = {
      email: email.trim(),
      senha: senha.trim(),
      sistema,
    };

    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const response = await api.post(`${resource}/login`, body, config);

    if (response === false) { 
      return {}; 
    } else if(Capacitor.isNativePlatform() && response.status === 400) {
      throw new Error(response.data.message);
    }

    const { data } = response.data;
    localStorage.setItem(process.env.VUE_APP_NAME_AUTH, `Bearer ${data.token}`);

    if(!Capacitor.isNativePlatform()) {
      api.defaults.headers.common.auth = `Bearer ${data.token}`;
    }
    
    return data;
  } catch (error) {
    const { response } = error;

    if(response && response.status === 307) {
      throw response.data;
    } else {
      throw new AppError(response, 400);
    }
  }
};

const buscarCorretor = async ({ cpf }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const { data } = await api.get(`${resource}/${cpf}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const buscarCorretorCadastro = async ({ cpf }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const { data } = await apiNoAuth.get(`${resource}/${cpf}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const buscarSerasa = async ({ cpf, entidade, rotina }) => {
  cpf = filters.removeSymbols(cpf);
  try {
    const entidadeParam = entidade ? `&entidade=${entidade}` : '';
    const rotinaParam = rotina ? `&rotina=${rotina}` : '';
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const { data } = await api.get(`${resource}/serasa/${cpf}?consulta_serasa=1${entidadeParam}${rotinaParam}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const buscarDadosClassificacao = async () => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const { data } = await api.get(`${resource}/classificacao`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const aceitaTermos = async ({ cpf }) => {
  const body = {
    cpf,
    termos: {
      qualivendas: true,
    },
  };

  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const data = await api.put(`${resource}/aceita-termos-portal`, body, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const enviarToken = async ({ cpf, payload = {} }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const data = await apiNoAuth.post(`${resource}/${cpf}/tokens/gerar`, payload, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const validarToken = async ({ cpf, token }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const data = await apiNoAuth.post(`${resource}/${cpf}/tokens/validar`, { token }, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const atualizaCorretor = async ({ cpf, dados }) => {
  const nascimento = dados.corretor.nascimento.split('/');
  dados.corretor.dataNascimento = `${nascimento[2]}-${nascimento[1]}-${nascimento[0]}`;
  dados.corretor.telefones = [
    {
      numero: filters.removeSymbols(dados.corretor.celular),
      tipo: 'Celular',
      preferencial: true,
    },
  ];
  dados.corretor.cpf = filters.removeSymbols(dados.corretor.cpf);
  dados = JSON.parse(JSON.stringify(dados));
  if (dados.endereco) {
    if (!dados.endereco.cep) {
      const { corretor } = dados;
      dados = { corretor };
    } else {
      dados.endereco.cep = filters.removeSymbols(dados.endereco.cep);
    }
  }
  if (dados.corretoras) {
    dados.corretoras = dados.corretoras.map((item) => item.cpfCnpj);
  }

  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    await api.put(`${resource}/${cpf}`, dados, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const atualizaCorretoras = async ({ cpf, corretoras }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    await api.put(`${resource}/${cpf}`, { corretoras }, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

// concluir cadastro: PUT -  /qvenda/corretores/:cpf
const concluirCadastro = async ({
  cpf, email, senha, corretoras, acessoRestrito, loginUsuarioRestrito,
}) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const data = await api.put(`${resource}/${cpf}`, {
      usuario: {
        email,
        senha,
        termos: {
          qualivendas: true,
        },
      },
      corretoras,
      acessoRestrito,
      loginUsuarioRestrito,
    }, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const adicionarEditarTelefone = async ({ cpf, telefone }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const { data } = await api.post(`${resource}/atualizar/telefone`, { cpf, telefone }, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const removerTelefone = async ({ telefoneId }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    await api.post(`${resource}/deletar/telefone`, { telefoneId }, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const atualizarEndereco = async ({ cpf, endereco }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const { data } = await api.post(`${resource}/atualizar/endereco`, { cpf, endereco }, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getFotoPerfil = async () => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const { data } = await api.get(`${resource}/usuarios/fotoperfil`, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const atualizarFotoPerfil = async (body) => {
  
  try {
    const headers = {
      headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY },
    };
    await api.post(`${resource}/usuarios/fotoperfil`, body, headers);
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const novaSenha = async (cpf, email) => {
  cpf = filters.removeSymbols(cpf);
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const { data } = await api.post(`${resource}/novaSenha`, { cpf, email }, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const alterarSenha = async ({ senhaAntiga, senhaNova, email }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const { data } = await api.put(`${resource}/alterarSenha`,
      {
        email,
        senhaNova,
        senhaAntiga,
        sistema: 'QVENDA',
      }, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const listaCorretorasVinculadas = async ({ cpf }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const { data } = await api.get(`${resource}/${cpf}/corretoras/solicitacoes`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const gerarTokenValidado = async ({ payload = {} }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const data = await apiNoAuth.post(`${resourceToken}/gerar/validado`, payload, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const criaLogTamoJunto = async ({ botao }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const { data } = await api.post(`${resource}/logs/tamo-junto`, { botao }, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const alterarDados = async (cpf, body) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const { data } = await api.put(`${resource}/${cpf}`, body, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const addTentativaConfirmacaoDados = async ({ cpf }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const { data } = await api.post(`${resource}/atualizar/tentativaConfirmacaoDados`, { cpf }, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const checkEmailTelefoneDisponiveis = async ({ cpf, telefone, email }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETOR_API_KEY } };
    const { data } = await api.post(`${resource}/atualizar/checkEmailTelefoneDisponiveis`, { cpf, telefone, email }, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const retornarStatusSolicitacao = async (guid) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_USUARIOS } };
    const { data } = await api.get(`usuarios/solicitacoes/status/${guid}`, config);
    return data.data ? data.data : {};
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  login,
  buscarCorretor,
  buscarCorretorCadastro,
  buscarSerasa,
  buscarDadosClassificacao,
  aceitaTermos,
  enviarToken,
  validarToken,
  atualizaCorretor,
  concluirCadastro,
  adicionarEditarTelefone,
  removerTelefone,
  atualizarEndereco,
  atualizaCorretoras,
  getFotoPerfil,
  atualizarFotoPerfil,
  novaSenha,
  alterarSenha,
  listaCorretorasVinculadas,
  gerarTokenValidado,
  criaLogTamoJunto,
  alterarDados,
  addTentativaConfirmacaoDados,
  checkEmailTelefoneDisponiveis,
  retornarStatusSolicitacao,
};
