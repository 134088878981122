// const hasUser = (state) => !!state.data.user;

// const userIsIntern = (state) => state.data.user.tipoCorretor.valor === '1';

const isMobile = (state) => state.user.isMobile;

const primeiroNome = ((state) => {
  let { nome } = state.profile;
  if (nome) {
    if (nome.includes(' ')) {
      [nome] = nome.split(' ').slice(0, 1);
      return nome.charAt(0).toUpperCase() + nome.slice(1).toLowerCase();
    }
    return nome;
  }
  return '';
});
export default {
  isMobile,
  primeiroNome,
  // hasUser,
  // userIsIntern,
};
