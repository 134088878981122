// eslint-disable-next-line import/no-extraneous-dependencies
// import { Response } from 'miragejs';

export const bancos = (schema, request) => [
  {
    codigo: '1',
    nome: 'BANCO CENTRAL DO BRASIL',
  },
  {
    codigo: '2',
    nome: 'BANCO ITAÚ',
  },
  {
    codigo: '3',
    nome: 'BANCO SANTANDER',
  },
  {
    codigo: '4',
    nome: 'BANCO BRADESCO',
  },
  {
    codigo: '5',
    nome: 'BANCO INTER',
  },
  {
    codigo: '6',
    nome: 'BANCO NUBANK',
  },
];
