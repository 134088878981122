var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-lgpd-settings-contents-base",
    {
      staticClass: "q-lgpd-settings-contents-privacity",
      attrs: { title: _vm.computedTitle },
      on: {
        "click-save": function ($event) {
          return _vm.$emit("click-save")
        },
      },
    },
    [
      _vm._t("default", function () {
        return [
          _c("p", [
            _vm._v(
              "Ao permitir esses cookies, você consegue armazenar suas preferências. Assim, você não precisa inserir seus dados mais de uma vez em um formulário, por exemplo."
            ),
          ]),
        ]
      }),
      _c("q-lgpd-switch", {
        attrs: {
          "activated-color": _vm.switchColors.activated,
          "deactivated-color": _vm.switchColors.deactivated,
          "activated-label": "Habilitado",
          "deactivated-label": "Desabilitado",
        },
        model: {
          value: _vm.enabled,
          callback: function ($$v) {
            _vm.enabled = $$v
          },
          expression: "enabled",
        },
      }),
      _vm.showCookieTable
        ? _c("q-lgpd-settings-cookie-table", {
            attrs: { cookies: _vm.cookies },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }