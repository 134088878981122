import filters from "@/filters";

function validadorCNS(cns) {
  const clearCns = filters.removeSymbols(cns);

  if (clearCns.match(/[1-2][0-9]{10}00[0-1][0-9]/) || clearCns.match(/[7-9][0-9]{14}/)) {
    let soma = 0;

    for (let i = 0; i < clearCns.length; i++) {
      soma += clearCns[i] * (15 - i);
    }

    return soma % 11 === 0;
  }

  return false;
}

export default validadorCNS;
