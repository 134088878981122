export default {
  SET_GUID(state, payload) {
    state.guid = payload.guid;
  },
  SET_TITULAR(state, payload) {
    state.titular = payload.titular;
  },
  SET_DEPENDENTES(state, payload) {
    state.dependentes = payload.dependentes;
  },
  SET_ENDERECO(state, payload) {
    state.endereco = payload.endereco;
  },
  SET_PLANO(state, payload) {
    state.plano = payload.plano;
  },
  SET_ANOTACAO(state, payload) {
    state.anotacao = payload.anotacao;
  },
  SET_ADMINISTRADORA(state, payload) {
    state.administradora = payload.administradora;
  },
  SET_EDITANDO(state, payload) {
    state.editando = payload.editando;
  },
  SET_CORRETORA(state, payload) {
    state.corretora = payload.cpfCnpj
  },
  RESET_DADOS(state, payload) {
    state.administradora = null;
    state.guid = '';
    state.titular = {};
    state.endereco = {};
    state.plano = {};
    state.anotacao = '';
    state.dependentes = [];
    state.editando = false;
    state.corretora = ''
  },
};
