<template>
  <button class="q-lgpd-button" :style="styleMap" @click="$emit(events.click)">
    <div class="q-lgpd-button__layer">
      <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    textColor: {
      type: String,
      default: '#fff',
    },
    backgroundColor: {
      type: String,
      default: '#171a88',
    },
  },

  data() {
    return {
      events: {
        click: 'click',
      },
    };
  },

  computed: {
    styleMap() {
      return {
        '--text-color': this.textColor,
        '--background-color': this.backgroundColor,
      };
    },
  },

};
</script>

<style lang="scss" scoped>
  .q-lgpd-button {
    min-width: 100px;
    font-family: var(--font-family);
    font-size: 18px;
    border-radius: 4px;
    background-color: var(--background-color, var(--primary-color, #6f6f6f));
    color: var(--text-color, #fff);

    &:hover > &__layer {
      background-color: #0005;
    }

    &__layer {
      width: 100%;
      height: 100%;
      padding: 0 16px;
      line-height: 42px;
      border-radius: 4px;
      background-color: #0000;
      pointer-events: none;
    }
 }
</style>
