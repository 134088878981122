export default {
  LOGOUT(state) {
    state.profile = {};
  },
  SET_CPF(state, payload) {
    state.cpf = payload;
  },
  SET_DATA(state, payload) {
    state.profile = { ...payload };
  },
  SET_URL_FOTO_PERFIL(state, payload) {
    state.urlFotoPerfil = payload || '';
  },
  CHECK_MOBILE(state, payload) {
    const isMobile = /iPhone|iPad|iPod|Android|BlackBerry|Windows Phone|webOS/i.test(navigator.userAgent);
    state.isMobile = isMobile;
  },
};
