import api from '@/services/api';
import AppError from '@/utils/appError';
import filters from '@/filters';
import { Capacitor } from '@capacitor/core';

const resource = 'propostas';

const lista = async ({ statusAgrupado, cpf, filtros = {} }) => {
  try {
    if (statusAgrupado) filtros.statusAgrupado = statusAgrupado.toUpperCase();

    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.post(`${resource}/lista-para-corretor`, {
      corretor: { cpf },
      filtros,
    }, config);
    const list = data && data.data ? data.data.map(filters.propostaMap) : [];

    return list;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const buscarDadosProposta = async ({ guidProposta }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.post(`${resource}/lista`, { guidProposta }, config);
    const list = data && data.data ? data.data.map(filters.propostaMap) : [];
    return list;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 500);
  }
};

const buscarAlteracaoAnexos = async ({ guidProposta, retornaArquivos }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const queryArquivos = retornaArquivos !== undefined ? `?retornaArquivos=${retornaArquivos}` : '';
    const result = await api.get(`${resource}/${guidProposta}/alteracaoAnexos${queryArquivos}`, config);
    return result.data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 500);
  }
};

const addOrEdit = async (guidProposta = '', form) => {
  try {
    form.criadoPor = guidProposta ? null : "Responsivo";
    form = filters.propostaToJson(form);
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.post(`${resource}/adicionaDados/${guidProposta}`, form, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getContadorPorStatus = ({ status }) => api.post('/propostas/contador', { status });

const getContadorDetalhado = ({ status }) => api.post('/propostas/contadorDetalhado', { status });

const getOperadoraCongenere = ({ nrProposta }) => api.post('/propostas/operadoraCongenere', { nrProposta });

const getPendencias = async ({ guidProposta }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.get(`${resource}/${guidProposta}/status`, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const getAdendo = async ({ guidProposta }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.get(`${resource}/${guidProposta}/adendo`, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const validarEnvioAcessoCliente = async ({ guidProposta }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.post(`${resource}/${guidProposta}/envioAcessoCliente/valida`, {}, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const enviarAcessoCliente = async ({ guidProposta, ipCorretor }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    await api.post(`${resource}/${guidProposta}/envioAcessoCliente`, { ipCorretor }, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const validaProposta = async ({
  guidProposta, matchConversao, transmitidoPor, retransmitidoPor,
}) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.post(
      `${resource}/checkout`,
      {
        guidProposta,
        matchConversao,
        transmitidoPor,
        retransmitidoPor,
        plataforma: Capacitor.isNativePlatform() ? `corretor app ${Capacitor.getPlatform()}` : "corretor web"
      },
      config
    );
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const atualizarEtapa = async ({ guidProposta, etapa }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    await api.put(`${resource}/${guidProposta}/etapa`, { etapa }, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const cancelarProposta = async ({ guidProposta, justificativa }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    await api.put(`${resource}/${guidProposta}/cancelar`, { justificativa }, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const clonarProposta = async ({ guidProposta }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    await api.post(`${resource}/${guidProposta}/clonar`, {}, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const associarProposta = async ({ guidProposta, tipoUsuario, idUsuario }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    await api.post(`${resource}/associar`, { guidProposta, tipoUsuario, idUsuario }, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const enviarAssinaturaAdendo = async ({ guidProposta }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    await api.post(`${resource}/${guidProposta}/adendo/assinaturaContrato`, {}, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const enviarAssinaturaAnexos = async ({ guidProposta }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    await api.post(`${resource}/${guidProposta}/alteracaoAnexos`, {}, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const getPublicIp = async () => {
  try {
    await api.get('https://api.ipify.org/?format=json', {
      headers: {
        'x-dsi-restful': 1,
      },
    });
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const removeDados = async ({ guidProposta, payload }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    await api.put(`${resource}/removeDados/${guidProposta}`, payload, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const listaOpcoesCarencia = async ({ idProdutoFatura, payload }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.post(`${resource}/titular/buscarOpcoesCarencia`, payload, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const getCamposFiliacao = async ({ guidProposta }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.get(`${resource}/filiacao-entidade/campos?proposta=${guidProposta}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const getTaxaFiliacaoEntidadeAbracem = async ({ idProdutoFatura }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.get(`${resource}/filiacao-entidade/abracem/taxa/${idProdutoFatura}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const getFiliacaoEntidadeAbracem = async ({ cpf }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.get(`${resource}/filiacao-entidade/abracem/${cpf}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const getFiliacaoEntidade = async (cpf, entidade) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.get(`${resource}/filiacao-entidade/:${entidade}/${cpf}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const enviaSolicitacaoAnexo = async ({ guidProposta }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.post(`${resource}/${guidProposta}/envioSolicitacaoAnexo`, {}, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const getPropostaHerdada = async ({ cpfTitular }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    return await api.get(`${resource}/titular/${cpfTitular}/herdadas`, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
}

const getAssinaturas = async ({ guidProposta }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    return await api.get(`${resource}/${guidProposta}/assinaturas`, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
}

const origemVenda = async ({ guidProposta, descricao, observacao }) => {
  try {
    const body = { descricao, observacao };
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    await api.post(`${resource}/${guidProposta}/origemVenda`, body, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const taxaValidarCobranca = async ({ guidProposta }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const result = await api.post(`${resource}/${guidProposta}/taxaAngariacao/validarCobranca`, {}, config);
    return result && result.data ? result.data.data : null;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const getStatusPagamentoTaxa = async (nrProposta) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const result = await api.get(`${resource}/${nrProposta}/taxaAngariacao/status`, config);
    return result && result.data ? result.data.data : null;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const enviarLinkPagamento = async (guidProposta) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const res = await api.post(`${resource}/${guidProposta}/taxaAngariacao/enviaAcessoPagamento`, {}, config);
    return res;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const verificaDependenteObrigatorio = async (entidade, operadora) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.get(`${resource}/dependenteObrigatorio?entidade=${entidade}&operadora=${operadora}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const solicitaDevolucaoPropostaDuplicada = async (guidProposta) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    await api.post(`${resource}/${guidProposta}/enviaSolicitacaoDevolucao`, {}, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

export default {
  getAssinaturas,
  enviaSolicitacaoAnexo,
  listaOpcoesCarencia,
  getPublicIp,
  lista,
  addOrEdit,
  getContadorPorStatus,
  getContadorDetalhado,
  getOperadoraCongenere,
  buscarDadosProposta,
  buscarAlteracaoAnexos,
  getPendencias,
  getAdendo,
  validarEnvioAcessoCliente,
  enviarAcessoCliente,
  validaProposta,
  atualizarEtapa,
  enviarAssinaturaAdendo,
  enviarAssinaturaAnexos,
  cancelarProposta,
  clonarProposta,
  associarProposta,
  removeDados,
  getCamposFiliacao,
  getTaxaFiliacaoEntidadeAbracem,
  getFiliacaoEntidadeAbracem,
  getPropostaHerdada,
  origemVenda,
  taxaValidarCobranca,
  getStatusPagamentoTaxa,
  enviarLinkPagamento,
  verificaDependenteObrigatorio,
  solicitaDevolucaoPropostaDuplicada,
  getFiliacaoEntidade
};
