var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dispositivoMovel
    ? _c("div", { staticClass: "q-lgpd-bar", style: _vm.styleMap }, [
        _c(
          "div",
          { staticClass: "q-lgpd-bar__text-wrapper" },
          [
            _vm._t("text", function () {
              return [
                _c("div", { attrs: { clas: "q-lgpd-bar__text" } }, [
                  _vm._v(
                    " Usamos cookies para proporcionar a melhor experiência de navegação em nosso site."
                  ),
                  _c("br"),
                  _vm._v(
                    " Para alterar os cookies e mais informações acesse as "
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "q-lgpd-bar__btn-settings",
                      on: {
                        click: function ($event) {
                          return _vm.$emit(_vm.events.clickSettings)
                        },
                      },
                    },
                    [_vm._v("Preferências")]
                  ),
                  _vm._v(" . Ao clicar em "),
                  _c("b", [_vm._v("“OK”")]),
                  _vm._v(" você aceita todos os cookies. "),
                ]),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "q-lgpd-bar__button-wrapper" },
          [
            _c(
              "q-lgpd-button",
              {
                staticClass: "q-lgpd-bar__btn-ok",
                attrs: {
                  color: _vm.theme.barButton,
                  textColor: _vm.theme.barButtonText,
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit(_vm.events.clickOk)
                  },
                },
              },
              [_vm._v(" OK ")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }