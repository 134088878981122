var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    { staticClass: "q-lgpd-footer" },
    [
      _c("div", { staticClass: "q-lgpd-footer__line" }),
      _c(
        "q-lgpd-button",
        {
          staticClass: "q-lgpd-footer__btn-salvar",
          on: {
            click: function ($event) {
              return _vm.$emit(_vm.events.clickSave)
            },
          },
        },
        [_vm._v("Salvar alterações")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }