<template>
  <q-lgpd-settings-contents-base
    class="q-lgpd-settings-contents-privacity"
    :title="computedTitle"
    @click-save="$emit('click-save')"
  >
    <slot>
      <p>
        Ao visitar um site, com a ajuda dos cookies, arquivos de internet que armazenam temporariamente seus acessos,
        entendemos seu comportamento dentro daquele ambiente. A ideia é que essas informações sejam utilizadas a seu favor,
        principalmente, para oferecer uma experiência mais personalizada, com produtos e serviços que façam a diferença para você.
        No entanto, em respeito ao seu direito de privacidade, é possível permitir ou não certos tipos de cookies,
        que estão disponíveis para você alterar em nossas configurações, a qualquer momento.
      </p>
    </slot>
  </q-lgpd-settings-contents-base>
</template>

<script>
// eslint-disable-next-line import/extensions
import QLgpdSettingsContentsBase from '../Base';
// eslint-disable-next-line import/extensions
import DefaultTitles from '../../../../constants/DefaultTitles.js';

export default {
  components: {
    QLgpdSettingsContentsBase,
  },

  props: {
    title: String,
  },

  computed: {
    computedTitle() {
      return this.title || DefaultTitles.PRIVACITY;
    },
  },
};
</script>

<style>

</style>
