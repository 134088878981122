// eslint-disable-next-line import/no-extraneous-dependencies
import { Response } from 'miragejs';

export const buscarSerasa = (schema, request) => {
  const { cpf } = JSON.parse(request.requestBody);

  switch (cpf) {
  case '99999999999':
    return new Response(400, {}, { errors: 'CPF não encontrado no Serasa' });
  default:
    return {
      nome: 'DENISSON DOS SANTOS CARVALHO DA SILVA CARVALHO',
      dataNascimento: '30/11/1994',
      sexo: {
        valor: 'm',
        descricao: 'Masculino',
      },
      nomeMae: 'Maria Eduarda',
      idade: 25,
    };
  }
};
