<template>
   <v-dialog :id="'alerta'" :retain-focus="false" v-model="dialog" persistent width="290" @keydown.esc="cancel">
      <v-card>
        <v-card-title class="size-16">{{ title }}</v-card-title>
        <v-card-text v-html="message" class="size-14 line-height"></v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-1">
          <v-spacer></v-spacer>
          <!-- <v-btn color="primary" text @click="agree()"><span class="size-12 font-weight-bold">continuar</span></v-btn> -->
            <v-btn :data-test-id="'recusar-alerta'" :color="`${options.cancelButtonColor} darken-1`" text @click="cancel">{{options.cancelButton}}</v-btn>
          <v-btn :data-test-id="'aceitar-alerta'" :color="`${options.confirmButtonColor} darken-1`" text @click="agree">{{options.confirmButton}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'TheConfirmDialog',
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: 'primary',
        width: 290,
        zIndex: 200,
        cancelButton: 'não',
        cancelButtonColor: 'primary',
        confirmButton: 'sim',
        confirmButtonColor: 'primary',
      },
    };
  },
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    close() {
      this.dialog = false;
      this.resolve(false);
    },
    agree() {
      this.dialog = false;
      this.resolve(true);
    },
    cancel() {
      this.dialog = false;
      this.resolve(false);
    },
  },
};
</script>

<style scoped>
  .line-height {
    line-height: 1.5;
  }
</style>
