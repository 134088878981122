var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "q-lgpd-settings-contents-base" },
    [
      _c("div", { staticClass: "q-lgpd-settings-contents-base__title" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c(
        "div",
        { staticClass: "q-lgpd-settings-contents-base__main" },
        [_vm._t("default")],
        2
      ),
      _c("q-lgpd-settings-footer", {
        staticClass: "q-lgpd-settings-contents-base__footer",
        on: {
          "click-save": function ($event) {
            return _vm.$emit(_vm.events.clickSave)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }