<template>
  <div v-show="!disbabled && visibility" class="quali-lgpd" :style="styleMap">
    <q-lgpd-settings
      v-if="settingsVisibility && !disbabled"
      class="quali-lgpd__settings"
      :cookies="cookies"
      :theme="currentTheme"
      @save-settings="acceptSomeCookies"
    >
    </q-lgpd-settings>

    <q-lgpd-bar
      v-if="barVisibility && !disbabled && !rodandoMobile"
      class="quali-lgpd__bar"
      :top-bar="topBar"
      :theme="currentTheme"
      @click-settings="showSettings"
      @click-ok="acceptAllCookies"
    >
      <template v-slot:text>
        <slot name="barText"></slot>
      </template>
    </q-lgpd-bar>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import QLgpdSettings from './components/Settings';
// eslint-disable-next-line import/extensions
import QLgpdBar from './components/Bar';
import { DEFAULT_THEME } from './constants/themes';
import { Capacitor } from '@capacitor/core'

export default {
  components: {
    QLgpdSettings,
    QLgpdBar,
  },

  props: {
    disbabled: {
      type: Boolean,
      default: false,
    },
    topBar: {
      type: Boolean,
      default: false,
    },
    fontFamily: {
      type: String,
      default: 'Galano-Grotesque',
    },
    theme: {
      type: Object,
      default: () => {},
    },
    qualiCookieName: {
      type: String,
      default: 'quali_lgpd',
    },
    storageItemName: {
      type: String,
      default: 'qualiLgpd',
    },
    cookies: {
      type: Object,
      default: () => {},
    },
    version: {
      type: String,
      default: '1',
    },
    persistInCookie: {
      type: Boolean,
      default: false,
    },
    customSettings: {
      type: Object,
      // ex { essential: 'custom_essential', thirdParty: 'customThirdParty', funcionality: 'custom-uncionaliy'}
    },

  },

  data() {
    return {
      // visibility control
      visibility: false,
      barVisibility: true,
      settingsVisibility: false,
    };
  },

  computed: {
    currentTheme() {
      return { ...DEFAULT_THEME, ...this.theme };
    },

    styleMap() {
      return {
        '--primary-color': this.primaryColor,
        '--secondary-color': this.secondaryColor,
        '--tab-color': this.tabColor,
        '--font-family': this.fontFamily,
      };
    },

    rodandoMobile() {
      return Capacitor.isNativePlatform();
    }
  },

  created() {
    const settings = this.getPersistedSettings();

    if (!settings) {
      this.visibility = true;
      return;
    }

    if (settings.version !== this.version) {
      this.visibility = true;
      return;
    }

    this.visibility = false;
  },

  methods: {
    showBar() {
      this.barVisibility = true;
    },

    hidenBar() {
      this.barVisibility = false;
    },

    showSettings() {
      this.settingsVisibility = true;
    },

    hideSettings() {
      this.settingsVisibility = false;
    },

    acceptSomeCookies(settings) {
      if (settings.funcionality) {
        this.startGoogleAnalitycs();
      }
      this.saveSettings(settings);
      this.deleteDisabledCookies(settings);
      this.visibility = false;
    },

    acceptAllCookies() {
      const settings = { essential: true, thirdParty: true, funcionality: true };
      this.saveSettings(settings);
      this.deleteDisabledCookies(settings);
      this.startGoogleAnalitycs();
      this.visibility = false;
    },

    saveSettings(settings) {
      // eslint-disable-next-line no-unused-expressions
      this.persistInCookie ? this.saveSettingsInCookies(settings) : this.saveSettingInStorage(settings);
    },

    startGoogleAnalitycs() {
      const script = document.createElement('script');
      // eslint-disable-next-line func-names
      script.innerHTML = (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
            new Date().getTime(),
          event: 'gtm.js',
        }); const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s); const
          dl = l !== 'dataLayer' ? `&l=${l}` : ''; j.async = true; j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`; f.parentNode.insertBefore(j, f);
      }(window, document, 'script', 'dataLayer', 'GTM-P4Q5RWG'));
      document.body.appendChild(script);
    },

    saveSettingInStorage(settings) {
      const objectValue = this.buildSettingsObjectToPersist(settings);
      const jsonValue = JSON.stringify(objectValue);
      localStorage.setItem('qualiLgpd', jsonValue);
    },

    saveSettingsInCookies(settings) {
      const objectValue = this.buildSettingsObjectToPersist(settings);
      const jsonValue = JSON.stringify(objectValue);

      const cookieValue = encodeURIComponent(jsonValue);
      const expiresDate = new Date();
      expiresDate.setFullYear(expiresDate.getFullYear() + 1);

      const cookie = `${this.qualiCookieName}=${cookieValue}; expires=${expiresDate.toUTCString()}`;
      document.cookie = cookie;
    },

    buildSettingsObjectToPersist({ essential, thirdParty, funcionality }) {
      const propEssential = this.customSettings ? this.customSettings.essential : 'essential';
      const propThirdParty = this.customSettings ? this.customSettings.thirdParty : 'thirdParty';
      const propFuncionality = this.customSettings ? this.customSettings.propFuncionality : 'funcionality';

      return {
        [propEssential]: essential,
        [propThirdParty]: thirdParty,
        [propFuncionality]: funcionality,
        version: this.version,
      };
    },

    getPersistedSettings() {
      return this.persistInCookie ? this.getQualiCookieValue() : this.getStorageValue();
    },

    getQualiCookieValue() {
      const strCookies = document.cookie;

      if (!strCookies) return null;

      const arrCookies = strCookies.split(';');
      const qualiCookies = arrCookies.find((cookie) => {
        const decCookie = decodeURIComponent(cookie);
        // console.log('decCookie', decCookie)
        return decCookie.trim().startsWith(`${this.qualiCookieName}=`);
      });

      if (!qualiCookies) return null;
      const decQualiCookies = decodeURIComponent(qualiCookies);
      const cookiesValue = decQualiCookies.trim().substring(this.qualiCookieName.length + 1);

      return JSON.parse(cookiesValue);
    },

    getStorageValue() {
      const value = localStorage.getItem('qualiLgpd');
      return value ? JSON.parse(value) : null;
    },

    deleteDisabledCookies(settings) {
      (this.cookies.essential || []).forEach((el) => {
        if (el.disabled) {
          const expiresDate = new Date(0);
          document.cookie = `${el.name}=; expires=${expiresDate.toUTCString()}`;
        }
      });

      (this.cookies.thirdParty || []).forEach((el) => {
        if (!settings.thirdParty || el.disabled) {
          const expiresDate = new Date(0);
          document.cookie = `${el.name}=; expires=${expiresDate.toUTCString()}`;
        }
      });

      (this.cookies.funcionality || []).forEach((el) => {
        if (!settings.funcionality || el.disabled) {
          const expiresDate = new Date(0);
          document.cookie = `${el.name}=; expires=${expiresDate.toUTCString()}`;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@/styles/variables";

  .quali-lgpd {
    --primary-color: $azul-qualicorp-hex;
    --secondary-color: #FFB61E;
    --text-color: #1A1919;
    --font-family: "Baloo Bhaina 2" !important;
  }
</style>

<style lang="scss" scoped>
  @import "./scss/_variables.scss";

  @media #{$breakpoint-desktop} {
    .quali-lgpd {
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;
      border-width: 0;
    }
  }

  @media #{$breakpoint-tablet} {
    .quali-lgpd {
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;
      border-width: 0;
    }
  }

  @media #{$breakpoint-mobile} {
    .quali-lgpd {
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;
      border-width: 0;
    }
  }

</style>
