<template>
  <footer class="q-lgpd-footer">
    <div class="q-lgpd-footer__line"></div>
    <q-lgpd-button class="q-lgpd-footer__btn-salvar" @click="$emit(events.clickSave)">Salvar alterações</q-lgpd-button>
  </footer>
</template>

<script>
// eslint-disable-next-line import/extensions
import QLgpdButton from '../../../Button';

export default {
  components: {
    QLgpdButton,
  },

  data() {
    return {
      events: {
        clickSave: 'click-save',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../../scss/_variables.scss";

  @media #{$breakpoint-desktop} {
    .q-lgpd-footer {
      width: 100%;

      &__line {
        width: 100%;
        height: 1px;
        border-bottom: 1px var(--window-content-footer-line-color) solid;
      }

      &__btn-salvar {
        float: right;
        margin-top: 30px;
      }
    }
  }
  @media #{$breakpoint-tablet} {
    .q-lgpd-footer {
      width: 100%;

      &__line {
        width: 100%;
        height: 1px;
        border-bottom: 1px var(--window-content-footer-line-color) solid;
      }

      &__btn-salvar {
        float: right;
        margin-top: 30px;
      }
    }
  }
  @media #{$breakpoint-mobile} {
    .q-lgpd-footer {
      width: 100%;

      &__line {
        width: 100%;
        height: 1px;
        border-bottom: 1px var(--window-content-footer-line-color) solid;
      }

      &__btn-salvar {
        width: 100%;
        margin-top: 20px;
      }
    }
  }

</style>
