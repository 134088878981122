<template>
  <div class="q-lgpd-settings-contents">
    <q-lgpd-settings-contents-privacity
      v-show="contentIndex === sections.PRIVACITY"
      :switch-colors="switchColors"
      :button-save-config="buttonSaveConfig"
      @click-save="evClickSave"
    >
      <slot name="privacity"></slot>
    </q-lgpd-settings-contents-privacity>

    <q-lgpd-settings-contents-essential
      v-show="contentIndex === sections.ESSENTIAL"
      :switch-colors="switchColors"
      :button-save-config="buttonSaveConfig"
      :cookies="cookies.essential"
      @click-save="evClickSave"
    >
      <slot name="essential"></slot>
    </q-lgpd-settings-contents-essential>

    <q-lgpd-settings-contents-third
      v-show="contentIndex === sections.THIRD"
      :switch-colors="switchColors"
      :button-save-config="buttonSaveConfig"
      :cookies="cookies.thirdParty"
      @click-save="evClickSave"
      @update-enabled="updateSettings('third', $event)"
    >
      <slot name="third"></slot>
    </q-lgpd-settings-contents-third>

    <q-lgpd-settings-contents-funcionality
      v-show="contentIndex === sections.FUNCIONALITY"
      :switch-colors="switchColors"
      :button-save-config="buttonSaveConfig"
      :cookies="cookies.funcionality"
      @click-save="evClickSave"
      @update-enabled="updateSettings('funcionality', $event)"
    >
      <slot name="funcionality"></slot>
    </q-lgpd-settings-contents-funcionality>

    <q-lgpd-settings-contents-policy
      v-show="contentIndex === sections.POLICY"
      :switch-colors="switchColors"
      :button-save-config="buttonSaveConfig"
      @click-save="evClickSave"
    >
      <slot name="policy"></slot>
    </q-lgpd-settings-contents-policy>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import QLgpdSettingsContentsPrivacity from './components/Privacity';
// eslint-disable-next-line import/extensions
import QLgpdSettingsContentsEssential from './components/Essential';

// eslint-disable-next-line import/extensions
import QLgpdSettingsContentsThird from './components/Third';
// eslint-disable-next-line import/extensions
import QLgpdSettingsContentsFuncionality from './components/Funcionality';
// eslint-disable-next-line import/extensions
import QLgpdSettingsContentsPolicy from './components/Policy';
import Sections from '../../constants/Sections';

export default {
  components: {
    QLgpdSettingsContentsPrivacity,
    QLgpdSettingsContentsEssential,
    QLgpdSettingsContentsThird,
    QLgpdSettingsContentsFuncionality,
    QLgpdSettingsContentsPolicy,
  },

  props: {
    contentIndex: {
      type: Number,
      required: true,
    },
    switchColors: {
      type: Object,
      required: true,
    },
    buttonSaveConfig: {
      type: Object,
      required: true,
    },
    cookies: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      sections: { ...Sections },
      funcionalityCookiesEnabled: true,
      thirdCookiesEnabled: true,
    };
  },

  methods: {
    updateSettings(section, value) {
      if (section === 'funcionality') {
        this.funcionalityCookiesEnabled = value;
        return;
      }

      if (section === 'third') {
        this.thirdCookiesEnabled = value;
      }
    },

    evClickSave() {
      const settings = {
        essential: true,
        thirdParty: this.thirdCookiesEnabled,
        funcionality: this.funcionalityCookiesEnabled,
      };

      this.$emit('save-settings', settings);
    },
  },
};
</script>

<style lang="scss" scoped>
  .q-lgpd-settings-contents {
    width: 100%;
    height: 100%;
  }
</style>
