import { extend, localize, setInteractionMode } from 'vee-validate';
import ptBR from 'vee-validate/dist/locale/pt_BR.json';
import {
  // eslint-disable-next-line camelcase
  required, email, required_if, min, confirmed, min_value, max_value,
} from 'vee-validate/dist/rules';
import dictionary from './dictionary';
import cpfValidate from './rules/cpfValidate';
import cnsValidate from './rules/cnsValidate';
import dateValid from './rules/dateValid';
import realDateValid from './rules/realDateValid';
import phoneValid from './rules/phoneValid';
import cellphoneValid from './rules/cellphoneValid';
import specialChars from './rules/specialChars';
import dddValid from './rules/dddValid';
import existenceCPF from './rules/cpfDependenteValidate';
import pisPasep from './rules/pisPasepValid';
import residencePhone from './rules/residencePhoneValid';

setInteractionMode('lazy');

extend('cns', cnsValidate);
extend('cpf', cpfValidate);
extend('date', dateValid);
extend('ddd', dddValid);
extend('phone', phoneValid);
extend('cellphone', cellphoneValid);
extend('realDate', realDateValid);
extend('email', email);
extend('required', required);
extend('requiredif', required_if);
extend('min', min);
extend('min_value', min_value);
extend('max_value', max_value);
extend('confirmed', confirmed);
extend('special_characters', specialChars);
extend('identidade', required);
extend('cpf_beneficiario', existenceCPF);
extend('pisPasep', pisPasep);
extend('residencePhone', residencePhone);

localize('pt-BR', ptBR);
localize(dictionary);
