import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#171a88',
        secundary: '#ffb61e',
        tertiary: '#1E659C',
        bgColor: '#171a88',
        bgColorSecundary: '#033d5e',
        logoClaro: '',
        logoEscuro: '',
      },
      dark: {
      },
    },
  },
});
