const state = {
  guid: '',
  titular: {},
  dependentes: [],
  endereco: {},
  plano: {},
  anotacao: '',
  administradora: '',
  editando: false,
  corretora: ''
};

export default state;
