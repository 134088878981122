import api from '@/services/api';
import AppError from '@/utils/appError';

const resource = 'layout';
const whiteLabelList = [
  {
    urls: [
      'celuladigitaldev.qualicorp.com.br',
      'celuladigitalhm.qualicorp.com.br',
    ],
    appName: 'QualiVendas',
    logoClaro: 'https://celuladigitaldev.qualicorp.com.br/QVendaCorretor/img/q_quali-w.15aba3bc.png',
    logoEscuro: 'https://celuladigitaldev.qualicorp.com.br/QVendaCorretor/img/q_quali-w.15aba3bc.png',
    logoComTexto: true,
    colors: {
      primary: '#171a88',
      secundary: '#687dff',
      tertiary: '#1E659C',
      bgColor: '#171a88',
      bgColorSecundary: '#033d5e',
    },
    welcome: {
      titulo: 'Bem-vindo<br />ao novo QualiVendas',
      descricao: 'Para ter acesso ao sistema, você deve estar<br/>previamente autorizado pela Qualicorp.<br/>',
      saibaMais: 'Saiba mais em <strong><a class="white--text" href="https://tamojuntocorretor.com.br" target="_blank">TamoJunto Corretor</a></strong>',
    },
  },
  {
    urls: [
      'vendasuniconsultdev.com.br',
      'vendasuniconsultlhm.com.br',
      'vendasuniconsult.com.br',
    ],
    appName: 'Vendas Uniconsult',
    logoClaro: '',
    logoEscuro: '',
    logoComTexto: true,
    colors: {
      primary: '#009963',
      secundary: '#107350',
      tertiary: '#06BF7E',
      bgColor: '#009963',
      bgColorSecundary: '#005B3D',
    },
    welcome: {
      titulo: `Bem-vindo<br />ao novo Vendas Uniconsult`,
      descricao: 'Para ter acesso ao sistema, você deve estar<br/>previamente autorizado pela Uniconsult.<br/>',
      saibaMais: 'Saiba mais em <strong><a class="white--text" href="https://tamojuntocorretor.com.br" target="_blank">TamoJunto Corretor</a></strong>',
    },
  },
];

const getVariaveis = async (url) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LAYOUT_API_KEY } };
    const { data } = await api.post(`${resource}/obter`, { url }, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  getVariaveis,
};
