var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-lgpd-settings-contents-base",
    {
      staticClass: "q-lgpd-settings-contents-privacity",
      attrs: { title: _vm.computedTitle },
      on: {
        "click-save": function ($event) {
          return _vm.$emit("click-save")
        },
      },
    },
    [
      _vm._t("default", function () {
        return [
          _c("p", [
            _vm._v(
              " Com eles, há como contar suas visitas e as fontes de tráfego para mensuração e possíveis ajustes no desempenho do site. Dessa forma, podemos saber quais páginas são mais ou menos relevantes. As informações coletadas por esses cookies são anônimas. Sem habilitar essa opção, não conseguimos saber quando você visitou nosso site e, portanto, não conseguimos monitorar o desempenho. "
            ),
          ]),
        ]
      }),
      _c("q-lgpd-switch", {
        attrs: {
          "activated-label": "Habilitado",
          "deactivated-label": "Desabilitado",
        },
        model: {
          value: _vm.enabled,
          callback: function ($$v) {
            _vm.enabled = $$v
          },
          expression: "enabled",
        },
      }),
      _vm.showCookieTable
        ? _c("q-lgpd-settings-cookie-table", {
            attrs: { cookies: _vm.cookies },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }