<template>
  <div class="q-lgpd-bar" :style="styleMap" v-if="dispositivoMovel">
    <div class="q-lgpd-bar__text-wrapper">
      <slot name="text">
        <div clas="q-lgpd-bar__text">
          Usamos cookies para proporcionar a melhor experiência de navegação em nosso site.<br>
          Para alterar os cookies e mais informações acesse as
          <span class="q-lgpd-bar__btn-settings" @click="$emit(events.clickSettings)">Preferências</span>
          . Ao clicar em <b>“OK”</b> você aceita todos os cookies.
        </div>
      </slot>
    </div>
    <div class="q-lgpd-bar__button-wrapper">
      <q-lgpd-button
        class="q-lgpd-bar__btn-ok"
        :color="theme.barButton"
        :textColor="theme.barButtonText"
        @click="$emit(events.clickOk)"
      >
        OK
      </q-lgpd-button>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import QLgpdButton from '../Button';
import { Capacitor } from '@capacitor/core'

export default {
  components: {
    QLgpdButton,
  },

  props: {
    top: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      events: {
        clickSettings: 'click-settings',
        clickOk: 'click-ok',
      },
    };
  },

  computed: {
    dispositivoMovel: () => Capacitor.isNativePlatform(),
    styleMap() {
      return {
        '--bar-text-color': this.theme.barText,
        '--bar-background-color': this.theme.barBackground,
        '--bar-border-color': this.theme.barBorder,
      };
    },
  },

};
</script>

<style lang="scss" scoped>
  @import "../../scss/_variables.scss";

  // --bar-text-color
  // --bar-background-color
  // --bar-border-color

  @media #{$breakpoint-desktop} {
    .q-lgpd-bar {
      position: fixed;
      z-index: 1000001;
      bottom: 30px;
      display: flex;
      justify-content: center;
      width: 90%;
      margin: 0 5%;
      padding: 35px 5%;
      border: var(--bar-border-color) 2px solid;
      border-radius: 10px ;
      box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
      background-color: var(--bar-background-color);
      font-family: var(--font-family);

      &__text-wrapper {
        font-size: 18px;
        color: var(--bar-text-color);
      }

      &__button-wrapper {
        display: flex;
        align-items: center;
        margin-left: 30px;
        color: var(--bar-text-color);
      }

      &__btn-settings {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
  @media #{$breakpoint-tablet} {
    .q-lgpd-bar {
      z-index: 1000001;
      position: fixed;
      bottom: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 90%;
      margin: 0 5%;
      padding: 35px 5%;
      border: var(--bar-border-color) 2px solid;
      border-radius: 10px ;
      box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
      background-color: var(--bar-background-color);
      font-family: var(--font-family);

      &__text-wrapper {
        font-size: 18px;
        color: var(--bar-text-color);
      }

      &__button-wrapper {
        display: flex;
        align-items: center;
        margin-top: 12px;
        margin-left: auto;
        color: var(--bar-text-color);
      }

      &__btn-settings {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
  @media #{$breakpoint-mobile} {
    .q-lgpd-bar {
      z-index: 1000001;
      position: fixed;
      bottom: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 90%;
      margin: 0 5%;
      padding: 20px 5%;
      border: var(--bar-border-color) 2px solid;
      border-radius: 10px ;
      box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
      background-color: var(--bar-background-color);
      font-family: var(--font-family);

      &__text-wrapper {
        font-size: 14px;
        color: var(--bar-text-color);
      }

      &__button-wrapper {
        margin-top: 0px;

      }

      &__btn-ok {
        width: 100%;
      }

      &__btn-settings {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }

</style>
