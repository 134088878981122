import initialState from './state';

export default {
  RESET(state) {
    Object.assign(state, initialState());
  },
  SET_CONTADOR(state, payload) {
    state.contador = payload;
  },
  SET_CONTADOR_EMAIL(state, payload) {
    state.contadorEmail = payload;
  },
  SET_ALL_CORRETORAS(state, payload) {
    state.corretoras = payload;
  },
  SET_CORRETORA(state, payload) {
    const index = state.corretoras.findIndex((corretora) => corretora.cpfCnpj === payload.cpfCnpj);
    if (index === -1) {
      state.corretoras = [...state.corretoras, payload];
    }
  },
  SET_REMOVE_CORRETORA(state, payload) {
    const index = state.corretoras.findIndex((corretora) => corretora.cpfCnpj === payload);
    state.corretoras.splice(index, 1);
  },
  SET_CORRETOR(state, payload) {
    state.corretor = { ...payload };
  },
  SET_ENDERECO(state, payload) {
    state.endereco = { ...payload };
  },
  SET_DATA(state, payload) {
    state.data = { ...payload };
  },
};
