var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading
    ? _c("div", [
        _c(
          "div",
          { attrs: { id: "loader-init-wrapper" } },
          [_c("vue-topprogress", { ref: "topProgress", attrs: { height: 6 } })],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }