<template>
  <q-lgpd-settings-contents-base
    class="q-lgpd-settings-contents-privacity"
    :title="computedTitle"
    @click-save="$emit('click-save')"
  >
    <slot>
      <p>
         Como o título já diz, esses cookies são necessários para o funcionamento do site e não podem ser desativados.
         Geralmente, são definidos apenas em resposta a suas ações.
         Por exemplo: solicitação de serviços, definição de preferências de privacidade,
         acesso ao login e preenchimento de formulários. É possível configurar seu navegador para bloquear ou para alertá-lo sobre esses cookies,
         mas, ainda assim, em algumas partes do site, isso não funcionará.
         Esses cookies não armazenam informações identificáveis.
      </p>
    </slot>

    <q-lgpd-settings-cookie-table v-if="showCookieTable" :cookies="cookies"/>

  </q-lgpd-settings-contents-base>
</template>

<script>
// eslint-disable-next-line import/extensions
import QLgpdSettingsCookieTable from '../../../CookieTable';
// eslint-disable-next-line import/extensions
import QLgpdSettingsContentsBase from '../Base';
// eslint-disable-next-line import/extensions
import DefaultTitles from '../../../../constants/DefaultTitles.js';

export default {
  components: {
    QLgpdSettingsCookieTable,
    QLgpdSettingsContentsBase,
  },

  props: {
    title: String,
    cookies: {
      type: Array,
      default: () => [],
      // model: {name: 'cookie1', source: 'Qualicorp', type: 'Essencial', duration: '365 dias' }
    },
  },

  computed: {
    computedTitle() {
      return this.title || DefaultTitles.ESSENTIAL;
    },

    showCookieTable() {
      return (this.cookies && this.cookies.length);
    },
  },
};
</script>
