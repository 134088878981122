import validaService from '@/services/validaService';
import _ from 'lodash';

const specialChars = async (value) => {
  try {
    const data = await validaService.validaNome(value);
    if (data === true) {
      return true;
    }

    return data.errors;
  } catch (error) {
    return error.message.split('\n').join(', ');
  }
};

const debouncedSpecialChars = _.debounce(specialChars, 350);
export { specialChars as default };
