var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-lgpd-settings-contents-base",
    {
      staticClass: "q-lgpd-settings-contents-privacity",
      attrs: { title: _vm.computedTitle },
      on: {
        "click-save": function ($event) {
          return _vm.$emit("click-save")
        },
      },
    },
    [
      _vm._t("default", function () {
        return [
          _c("p", [
            _vm._v(
              " Como o título já diz, esses cookies são necessários para o funcionamento do site e não podem ser desativados. Geralmente, são definidos apenas em resposta a suas ações. Por exemplo: solicitação de serviços, definição de preferências de privacidade, acesso ao login e preenchimento de formulários. É possível configurar seu navegador para bloquear ou para alertá-lo sobre esses cookies, mas, ainda assim, em algumas partes do site, isso não funcionará. Esses cookies não armazenam informações identificáveis. "
            ),
          ]),
        ]
      }),
      _vm.showCookieTable
        ? _c("q-lgpd-settings-cookie-table", {
            attrs: { cookies: _vm.cookies },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }