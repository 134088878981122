var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "q-lgpd-settings-cookie-table" }, [
    _c(
      "div",
      {
        staticClass: "q-lgpd-settings-cookie-table__btn-details",
        on: { click: _vm.toggleDetailVisibility },
      },
      [_vm._v(" " + _vm._s(_vm.btnDetailsText) + " ")]
    ),
    _c(
      "table",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showTable,
            expression: "showTable",
          },
        ],
        staticClass: "q-lgpd-settings-cookie-table__table",
      },
      [
        _vm._m(0),
        _c(
          "tbody",
          _vm._l(_vm.enabledCookies, function (cookie, index) {
            return _c("tr", { key: index }, [
              _c("td", { attrs: { "aria-label": "Cookie" } }, [
                _vm._v(_vm._s(cookie.name)),
              ]),
              _c("td", { attrs: { "aria-label": "Origem" } }, [
                _vm._v(_vm._s(cookie.source)),
              ]),
              _c("td", { attrs: { "aria-label": "Tipo" } }, [
                _vm._v(" " + _vm._s(cookie.type) + " "),
              ]),
              _c("td", { attrs: { "aria-label": "Duração" } }, [
                _vm._v(_vm._s(cookie.duration)),
              ]),
            ])
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Cookie")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Origem")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Tipo")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Duração")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }