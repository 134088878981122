var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "the-wrapper-auto-height",
    [
      _c(
        "div",
        [
          _c("Cookie", {
            attrs: {
              cookies: {
                essential: [
                  {
                    name: "moove_gdpr",
                    source: "Qualicorp",
                    type: "Essencial",
                    duration: "365 dias",
                  },
                ],
                funcionality: [
                  {
                    name: "_ga",
                    source: "Qualicorp",
                    type: "Funcionalidade",
                    duration: "720 dias",
                  },
                  {
                    name: "_gid",
                    source: "Qualicorp",
                    type: "Funcionalidade",
                    duration: "24 horas",
                  },
                  {
                    name: "_gcl_au",
                    source: "Qualicorp",
                    type: "Funcionalidade",
                    duration: "24 horas",
                  },
                ],
              },
              theme: (_vm.theme = {
                windowContentCookieTableHeader: "#171a88",
                windowContentCookieTableHeaderText: "#fff",
              }),
              persistInCookie: true,
              qualiCookieName: "moove_gdpr",
            },
          }),
        ],
        1
      ),
      _c("LoadingInit"),
      _c(
        "v-app",
        [
          _c("the-confirm-dialog", { ref: "confirmdialog" }),
          _c("the-snack-bar", { ref: "snackBar" }),
          _c(
            "div",
            {
              class: ["auto", {}],
              style: {
                backgroundColor: !_vm.isLoggedIn
                  ? _vm.$store.getters.getColor("primary")
                  : "",
              },
            },
            [
              _c(
                "v-fade-transition",
                { attrs: { "leave-absolute": "", "hide-on-leave": "" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400", persistent: "" },
          model: {
            value: _vm.$store.getters.getTermoUso.dialog,
            callback: function ($$v) {
              _vm.$set(_vm.$store.getters.getTermoUso, "dialog", $$v)
            },
            expression: "$store.getters.getTermoUso.dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _c("div", { staticClass: "modal-title" }, [
                  _vm._v(
                    " Os termos de Uso e Política de Privacidade foram atualizados "
                  ),
                ]),
              ]),
              _c("v-card-text", { staticClass: "card-visualizar-termos" }, [
                _c(
                  "div",
                  {
                    staticClass: "blue--text cursor-pointer mt-3 pr-1",
                    on: {
                      click: function ($event) {
                        return _vm.abrirTermos(true)
                      },
                    },
                  },
                  [
                    _c("v-icon", [_vm._v("mdi-chevron-right")]),
                    _vm._v(" Visualizar termos de uso "),
                  ],
                  1
                ),
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-checkbox", {
                    attrs: {
                      value: _vm.$store.getters.getTermoUso.check,
                      label:
                        "Concordo com os termos de uso e política de privacidade.",
                      color: "primary",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.abrirTermos(false)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "cursor-pointer",
                      attrs: {
                        color: "orange darken-1",
                        text: "",
                        disabled: _vm.authLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.desisteTermo()
                        },
                      },
                    },
                    [_vm._v(" Desistir ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "cursor-pointer",
                      attrs: {
                        color: "green darken-1",
                        text: "",
                        disabled: !_vm.$store.getters.getTermoUso.check,
                        loading: _vm.authLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.aceitar()
                        },
                      },
                    },
                    [_vm._v(" continuar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }