import usuarioService from '@/services/usuarioService';
import filters from '@/filters';
import moment from 'moment';

export default {
  reset({ commit }) {
    commit('RESET');
  },
  setAllCorretoras({ commit }, payload) {
    commit('SET_ALL_CORRETORAS', payload);
  },
  setCorretora({ commit }, payload) {
    commit('SET_CORRETORA', payload);
  },
  removeCorretora({ commit }, payload) {
    commit('SET_REMOVE_CORRETORA', payload);
  },
  setCorretor({ commit }, payload) {
    commit('SET_CORRETOR', payload);
  },
  setEndereco({ commit }, payload) {
    commit('SET_ENDERECO', payload);
  },
  setContador({ commit, dispatch }, payload) {
    if (payload >= 0) {
      setTimeout(() => {
        commit('SET_CONTADOR', payload--);
        dispatch('setContador', payload--);
      }, 1000);
    }
  },
  setContadorEmail({ commit, dispatch }, payload) {
    if (payload >= 0) {
      setTimeout(() => {
        commit('SET_CONTADOR_EMAIL', payload--);
        dispatch('setContadorEmail', payload--);
      }, 1000);
    }
  },
  async validaCorretorPorCpf({ commit }, payload) {
    let { cpf } = payload;
    cpf = filters.removeSymbols(cpf);
    const response = await usuarioService.buscarCorretorCadastro({ cpf });
    if (response) {
      if (response.usuarioValidado && !response.etapaCadastroInicial) return response;
      const {
        id,
        endereco,
        corretoras,
        nome, tipoCorretor,
        contato, dataNascimento,
        isCorretorInterno,
        subTipoCorretor,
        etapaCadastroInicial,
        listaAtencao,
        acessoRestrito,
        dadosSerasa,
        usuarioValidado,
      } = response;
      const data = {
        corretor: {
          id,
          nome,
          cpf,
          email: contato.email,
          nascimento: dataNascimento ? moment(dataNascimento).format('DD/MM/YYYY') : '',
          celular: contato.telefones.length > 0 ? contato.telefones[0].numero : '',
          tipoCorretor,
          subTipoCorretor,
          isCorretorInterno,
          etapaCadastroInicial,
          listaAtencao,
          acessoRestrito,
          dadosSerasa,
          usuarioValidado,
        },
        endereco,
      };
      return data;
    }
    return false;
  },
};
