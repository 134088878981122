class AppError {
  constructor(response, statusCode = 400) {
    let msg = "";

    if (response) {
      if (response && response.data && response.data.message) {
        msg = response.data.message;
      }

      if (response.message) {
        msg = response.message;
      }

      if (typeof response === "string") {
        msg = response;
      }

      if (response && response.data && response.data.message === "'auth' inválido ou vencido.") {
        msg = "Tempo de login excedido.";
      }
    }

    this.message = msg;
    this.statusCode = statusCode;
  }
}

module.exports = AppError;
