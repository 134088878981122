import { CapacitorHttp } from '@capacitor/core'

const apiCapacitor = {};
const baseDomain = process.env.VUE_APP_BASE_URL;

apiCapacitor.post = async (url, data, { params, headers }) => {
    const token = localStorage.getItem(`${process.env.VUE_APP_NAME_AUTH}`);

    const response = await CapacitorHttp.post({
        url: baseDomain + url,
        headers : {
            'content-type':'application/json; charset=utf-8',
            auth: token ? token : undefined,
            'X-Gravitee-Api-Key': headers['X-Gravitee-Api-Key']
        },
        data: data,
        params: params
    });
    if(response.status !== 200) {
        throw {response: response};
    }
    return response;
}

apiCapacitor.get = async (url, { params, headers }) => {
    const token = localStorage.getItem(`${process.env.VUE_APP_NAME_AUTH}`);
    const response = await CapacitorHttp.get({
        url: baseDomain + url,
        headers : {
            'content-type':'application/json; charset=utf-8',
            auth: token ? token : undefined,
            'X-Gravitee-Api-Key': headers['X-Gravitee-Api-Key']
        },
        params: params
    });
    if(response.status !== 200) {
        throw {response: response};
    }
    return response;
}

apiCapacitor.put = async (url, data, { headers }) => {
    const token = localStorage.getItem(`${process.env.VUE_APP_NAME_AUTH}`);
    return await CapacitorHttp.put({
        url: baseDomain + url,
        headers : {
            'content-type':'application/json; charset=utf-8',
            auth: token ? token : undefined,
            'X-Gravitee-Api-Key': headers['X-Gravitee-Api-Key']
        },
        data: data
    });
}

apiCapacitor.delete = async (url, { data, headers }) => {
    const token = localStorage.getItem(`${process.env.VUE_APP_NAME_AUTH}`);
    return await CapacitorHttp.delete({
        url: baseDomain + url,
        headers : {
            'content-type':'application/json; charset=utf-8',
            auth: token ? token : undefined,
            'X-Gravitee-Api-Key': headers['X-Gravitee-Api-Key']
        },
        data: data
    });
}

export default apiCapacitor;