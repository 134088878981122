import { isValid, parse } from 'date-fns';
import moment from 'moment';

function validate(value) {
  if (moment(value, 'DD/MM/YYYY', true).isValid()) {
    const age = moment().diff(moment(value, 'DD/MM/YYYY'), 'years');
    if (age < 111 && age > -1) return true;
    return false;
  }
  return false;
}
// export default (value) => isValid(parse(value, 'dd/MM/yyyy', new Date()));

export default validate;
