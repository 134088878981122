const currency = (value = 0) => {
  const formatter = new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
};

export default {
  'pt-BR': {
    messages: {
      cpf: (field) => `Número de ${field} inválido`,
      cns: (field) => `Número de ${field} inválido`,
      min_value: (field, params) => `O valor deve ser no mínimo de ${params.min}`,
      max_value: (field, params) => `O valor deve ser no máximo de ${params.max}`,
      confirmed: () => 'Confirmação de senha inválida',
      date: () => 'Data inválida',
      realDate: () => 'Data inválida',
      requiredif: () => 'Campo Obrigatório',
      phone: () => 'Telefone inválido',
      cellphone: () => 'Celular inválido',
      residencePhone: () => 'Telefone residencial inválido',
      ddd: () => 'DDD inválido',
      pisPasep: () => 'PIS/PASEP inválido',
    },
  },
};
