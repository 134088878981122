<template>
  <button class="q-lgpd-settings-tabs-item" :class="classMap" @click="$emit('click')">
    <div class="q-lgpd-settings-tabs-item__icon-wrapper" v-html="svgIcon">

    </div>
    <div class="q-lgpd-settings-tabs-item__text">{{ text }}</div>
  </button>

</template>

<script>
export default {
  props: {
    activated: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    svgIcon: {
      type: String,
      default: '',
    },
  },

  computed: {
    classMap() {
      return {
        'q-lgpd-settings-tabs-item--activated': this.activated,
      };
    },
  },

};
</script>

<style lang="scss">
  .q-lgpd-settings-tabs-item {
    &__icon-wrapper {
      svg * {
        fill: var(primary-color)
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import "../../../../../../scss/_variables.scss";

  // --window-tab-item-color
  // --window-tab-item-text-color
  // --window-hover-tab-item-color
  // --window-hover-tab-item-text-color
  // --window-selected-tab-item-color
  // --window-selected-tab-item-text-color

  @media #{$breakpoint-desktop} {
    .q-lgpd-settings-tabs-item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 58px;
      padding: 0 20px;
      border: 2px var(--window-tab-item-color) solid;
      border-radius: 5px;
      background-color: var(--window-tab-item-color);
      color: var(--window-tab-item-text-color);
      font-family: var(--font-family);

      &:hover {
        background-color: var(--window-hover-tab-item-color);
        color: var(--window-hover-tab-item-text-color);
      }

      &--activated {
        background-color: var(--window-selected-tab-item-color) !important;
        color: var(--window-selected-tab-item-text-color) !important;
      }

      &__icon-wrapper {
        display: flex;
        width: 25px;
        margin-right: 15px;
      }

      &__text {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  @media #{$breakpoint-tablet} {
    .q-lgpd-settings-tabs-item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 45px;
      padding: 0 16px;
      border: 2px var(--window-tab-item-color) solid;
      border-radius: 5px;
      background-color: var(--window-tab-item-color);
      color: var(--window-tab-item-text-color);
      font-family: var(--font-family);

      &:hover {
        background-color: var(--window-hover-tab-item-color);
        color: var(--window-hover-tab-item-text-color);
      }

      &--activated {
        background-color: var(--window-selected-tab-item-color) !important;
        color: var(--window-selected-tab-item-text-color) !important;
      }

      &__icon-wrapper {
        display: flex;
        width: 20px;
        margin-right: 10px;
      }

      &__text {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  @media #{$breakpoint-mobile} {
    .q-lgpd-settings-tabs-item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 45px;
      padding: 0 16px;
      border: 2px var(--window-tab-item-color) solid;
      border-radius: 5px;
      background-color: var(--window-tab-item-color);
      color: var(--window-tab-item-text-color);
      font-family: var(--font-family);

      &:hover {
        background-color: var(--window-hover-tab-item-color);
        color: var(--window-hover-tab-item-text-color);
      }

      &--activated {
        color: var(--window-selected-tab-item-text-color) !important;
        background-color: var(--window-selected-tab-item-color) !important;
      }

      &__icon-wrapper {
        display: flex;
        width: 20px;
        margin: auto;
      }

      &__text {
        display: none;
      }
    }
  }
</style>
