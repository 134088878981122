import axios from 'axios';
import { Capacitor } from '@capacitor/core'
import apiCapacitor from './apiCapacitor';

const token = localStorage.getItem(`${process.env.VUE_APP_NAME_AUTH}`);

const baseDomain = process.env.VUE_APP_BASE_URL;
let api;

if (Capacitor.isNativePlatform()) {
  api = apiCapacitor;
} else {
  api = axios.create({
    baseURL: baseDomain,
  });
}

if (token && !Capacitor.isNativePlatform()) {
  api.defaults.headers.common.auth = token;
  //api.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
}

export default api;
