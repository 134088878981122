// const hasUser = (state) => !!state.data.user;

// const userIsIntern = (state) => state.data.user.tipoCorretor.valor === '1';
const planoAtual = (state) => state.proposta.plano;
const dependentesAtual = (state) => state.proposta.dependentes;

export default {
  dependentesAtual,
  planoAtual,
};
