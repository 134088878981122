import api from "@/services/api";
import AppError from "@/utils/appError";

const resource = "precos";

const cartaReajuste = async ({ periodoAtribuicao, periodoVigencia, idProdutoFatura }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PRECO_API_KEY } };
    const { data } = await api.post(`${resource}/cartaReajuste`, { periodoAtribuicao, periodoVigencia, idProdutoFatura }, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  cartaReajuste,
};
