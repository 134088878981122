import AppError from '@/utils/appError';

const resource = 'propostas';
const validaNome = async (nome) => {
  let errors = [];
  if (nome) {
    // eslint-disable-next-line
    const format = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]+/;

    if (format.test(nome)) {
      errors.push("Não pode conter caracteres especiais");
    }

    const number = /\d/;
    if (number.test(nome)) {
      errors.push("Não pode conter números");
    }

    const letrasRepetidas = nome.split("").reduce(
      (acc, item) => {
        if (acc.possuiLetraRepetida) {
          return acc;
        }
        if (!item.trim()) {
          acc.quantidade = 0;
          return acc;
        }

        if (item.toUpperCase() === acc.caracterAnterior) {
          acc.quantidade += 1;
        } else {
          acc.quantidade = 0;
        }

        acc.possuiLetraRepetida = acc.quantidade === 2;
        acc.caracterAnterior = item.toUpperCase();

        return acc;
      },
      { possuiLetraRepetida: false, caracterAnterior: "", quantidade: 0 },
    );

    if (letrasRepetidas.possuiLetraRepetida) {
      errors.push("Nome não pode ter caracteres repetidos");
    }

    const permiteAbreviacao = ["D", "I", "O", "U", "Y"];
    const abreviaUltimoNome = ["I", "O", "U", "Y"];

    const splitNome = nome.trim().split(" ");

    if (splitNome.length <= 1) {
      errors.push("Deve ser preenchido completo");
    }

    for (let index = 0; index < splitNome.length; index++) {
      if (index === 0 && splitNome[index].length === 1 && !permiteAbreviacao.includes(splitNome[0])) {
        errors.push("Primeiro nome não pode ser abreviado");
      }

      if (index > 0 && index < splitNome.length - 1 && splitNome[index].length === 1 && splitNome[index].toUpperCase() !== 'E') {
        errors = [];
        errors.push("O nome não pode ser abreviado");
      }

      if (
        index === splitNome.length - 1
          && splitNome[index].length === 1
          && !abreviaUltimoNome.includes(splitNome[index])
      ) {
        errors.push("Último nome não pode ser abreviado");
      }
    }

    if (errors.length) {
      return { errors: errors.join("\n") };
    }
  } else {
    throw new AppError("O nome deve ser preenchido");
  }

  return true;
};

const validaRG = (rg) => {
  let response;
  if (rg) {
    const regex = /[`!@#$%^&*()_+={};':"\\|,<>/?~]/g;
    response = regex.test(rg);
  } else {
    response = false;
  }

  return response;
};

export default {
  validaRG,
  validaNome,
};
