import { getPlanoSimilar } from "@/mixins/getPlanoSimilar";
import precoService from "@/services/precosService";
import planoService from "@/services/planoService";
import state from './state';

export default {
  logout({ commit }) {
    commit('LOGOUT');
  },
  setIdLead({ commit }, payload) {
    commit('SET_ID_LEAD', payload);
  },
  setDadosPessoais({ commit }, payload) {
    commit('SET_DADOS_PESSOAIS', payload);
  },
  setCorretora({ commit }, payload) {
    commit('SET_CORRETORA', payload);
  },
  setOperadora({ commit }, payload) {
    commit('SET_OPERADORA', payload);
  },
  setPlano({ commit }, payload) {
    commit('SET_PLANO', payload);
  },
  setVigencia({ commit }, payload) {
    commit('SET_VIGENCIA', payload);
  },
  setDadosTitular({ commit }, payload) {
    commit('SET_DADOS_TITULAR', payload);
  },
  setDadosProfissionais({ commit }, payload) {
    commit('SET_DADOS_PROFISSIONAIS', payload);
  },
  setDadosFiliacao({ commit }, payload) {
    commit('SET_DADOS_FILIACAO', payload);
  },
  setDadosDependentes({ commit }, payload) {
    commit('SET_DADOS_DEPENDENTES', payload);
  },
  updateDependente({ commit }, payload) {
    commit('UPDATE_DEPENDENTE', payload);
  },
  setDadosFinanceiros({ commit }, payload) {
    commit('SET_DADOS_FINANCEIROS', payload);
  },
  setDadosPagamento({ commit }, payload) {
    commit('SET_DADOS_PAGAMENTO', payload);
  },
  setDadosReembolso({ commit }, payload) {
    commit('SET_DADOS_REEMBOLSO', payload);
  },
  setDadosDocumentos({ commit }, payload) {
    commit('SET_DADOS_DOCUMENTOS', payload);
  },
  setDadosProposta({ commit }, payload) {
    commit('SET_DADOS_PROSPOSTA', payload);
  },
  setDadosPortabilidade({ commit }, payload) {
    commit('SET_DADOS_PORTABILIDADE', payload);
  },
  setDadosSumula({ commit }, payload) {
    commit('SET_DADOS_SUMULA', payload);
  },
  setDadosAdministradora({ commit }, payload) {
    commit('SET_DADOS_ADMINISTRADORA', payload);
  },
  resetDadosProposta({ commit }, payload) {
    commit('RESET_DADOS_PROSPOSTA', payload);
  },
  resetDadosPlano({ commit }, payload) {
    commit('RESET_DADOS_PLANO', payload);
  },
  setBlnContinuar({ commit }, payload) {
    commit('SET_CONTINUAR_DADOS', payload);
  },
  setResponsavelLegal({ commit }, payload) {
    commit('SET_RESPONSAVEL_LEGAL', payload);
  },
  setPendencias({ commit }, payload) {
    commit('SET_PENDENCIAS', payload);
  },
  setAdendo({ commit }, payload) {
    commit('SET_ADENDO', payload);
  },
  setTipoTaxaAngariacao({ commit }, payload) {
    commit('SET_TIPO_TAXA_ANGARIACAO', payload);
  },
  setValorTaxaAngariacao({ commit }, payload) {
    commit('SET_VALOR_TAXA_ANGARIACAO', payload);
  },
  setjustificativaTaxaAngariacao({ commit }, payload) {
    commit('SET_JUSTIFICATIVA_TAXA', payload);
  },
  setIdFilial({ commit }, payload) {
    commit('SET_ID_FILIAL', payload);
  },
  setProdutosOpcionais({ commit }, payload) {
    commit('SET_PRODUTOS_OPCIONAIS', payload);
  },
  setCorretoraRepasse({ commit }, payload) {
    commit('SET_CORRETORA_REPASSE', payload);
  },
  setEntidadeAlterada({ commit }, payload) {
    commit('SET_ENTIDADE_ALTERADA', payload);
  },
  async setVerificarReajustePlano({ commit }, dataVigenciaSelecionada) {
    if (state.plano && state.plano.idProdutoFatura) {
      const reajuste = await precoService.cartaReajuste({
        periodoAtribuicao: state.plano && state.plano.dataAtribuicaoPlano,
        periodoVigencia: dataVigenciaSelecionada,
        idProdutoFatura: state.plano && state.plano.idProdutoFatura,
      });

      const exibirDialogCartaReajuste = reajuste.possuiReajuste && !reajuste.possuiTabela;
      if (exibirDialogCartaReajuste) {
        return false;
      }
      return true;
    }
    return true;
  },
  // async getPlano({ commit }, payload) {
  //   if (!(state.plano && state.plano.idProdutoFatura)) return;
  //   const input = {
  //     idProdutoFatura: state.plano.idProdutoFatura,
  //     cpfCnpjCorretora: state.corretora.cpfCnpj,
  //   };
  //   const { data } = await planoService.getPlano({ input, administradora: state.administradora });
  //   return data;
  // },
};
