<template>
  <q-lgpd-settings-contents-base
    class="q-lgpd-settings-contents-privacity"
    :title="computedTitle"
    @click-save="$emit('click-save')"
  >
    <slot>
      <p>
        Com eles, há como contar suas visitas e as fontes de tráfego para mensuração e possíveis ajustes no desempenho do site.
        Dessa forma, podemos saber quais páginas são mais ou menos relevantes. As informações coletadas por esses cookies são anônimas.
        Sem habilitar essa opção, não conseguimos saber quando você visitou nosso site e, portanto, não conseguimos monitorar o desempenho.
      </p>
    </slot>

    <q-lgpd-switch
      v-model="enabled"
      activated-label="Habilitado"
      deactivated-label="Desabilitado"
    />

    <q-lgpd-settings-cookie-table v-if="showCookieTable" :cookies="cookies"/>

  </q-lgpd-settings-contents-base>
</template>

<script>
// eslint-disable-next-line import/extensions
import QLgpdSwitch from '../../../../../Switch';
// eslint-disable-next-line import/extensions
import QLgpdSettingsCookieTable from '../../../CookieTable';
// eslint-disable-next-line import/extensions
import QLgpdSettingsContentsBase from '../Base';
// eslint-disable-next-line import/extensions
import DefaultTitles from '../../../../constants/DefaultTitles.js';

export default {
  components: {
    QLgpdSwitch,
    QLgpdSettingsCookieTable,
    QLgpdSettingsContentsBase,
  },

  props: {
    title: String,
    cookies: {
      type: Array,
      default: () => [],
      // model: {name: 'cookie1', source: 'Qualicorp', type: 'Essencial', duration: '365 dias' }
    },
  },

  data() {
    return {
      enabled: true,
    };
  },

  watch: {
    enabled: {
      immediate: true,
      handler(value) {
        this.$emit('update-enabled', value);
      },
    },
  },

  computed: {
    computedTitle() {
      return this.title || DefaultTitles.THIRD;
    },

    showCookieTable() {
      return (this.cookies && this.cookies.length);
    },
  },
};
</script>
