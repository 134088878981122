// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Server, Model,
} from 'miragejs';
import routes from './routes';

export function makeServer({ environment = 'development' } = {}) {
  return new Server({
    environment,

    routes,
  });
}
