// eslint-disable-next-line import/no-extraneous-dependencies
import { Response } from 'miragejs';

export const planosPorProposta = (schema, request) => {
  const { nrProposta } = JSON.parse(request.requestBody);

  if (!nrProposta) {
    return new Response(400, {}, { errors: 'Numero da proposta é obrigatorio' });
  }

  return [
    {
      id: '1',
      logo:
          'https://groupsaude.com.br/wp-content/uploads/2019/11/Amil-1080x675.png',
      nome: 'QUALICORP ESTILO NACIONAL ADS I - E (EA)',
      operadora: 'AMIL SAÚDE - AMIL FÁCIL',
      valor: {
        total: 400.10,
        beneficiarios: [
          {
            id: '1',
            nome: 'DENISSON DOS SANTOS CARVALHO',
            tipo: 'Titular',
            valor: 200.05,
          },
          {
            id: '2',
            nome: '10/05/1994 - 25 ANOS',
            tipo: 'Dependente',
            valor: 200.05,
          },
        ],
      },
      abrangencia: 'NACIONAL',
      acomodacao: 'COLETIVA',
      coparticipacao: true,
    },
    {
      id: '2',
      logo:
          'https://groupsaude.com.br/wp-content/uploads/2019/11/Amil-1080x675.png',
      nome: 'QUALICORP ESTILO NACIONAL ADS E - R (EA)',
      operadora: 'AMIL SAÚDE - AMIL FÁCIL',
      valor: {
        total: 200.5,
        beneficiarios: [
          {
            id: '1',
            nome: 'DENISSON DOS SANTOS CARVALHO',
            tipo: 'Titular',
            valor: 100.05,
          },
          {
            id: '2',
            nome: 'ANGIELI RODRIGUES',
            tipo: 'Dependente',
            valor: 100.00,
          },
        ],
      },
      abrangencia: 'NACIONAL',
      acomodacao: 'COLETIVA',
      coparticipacao: true,
    },
    {
      id: '3',
      logo:
          'https://groupsaude.com.br/wp-content/uploads/2019/11/Amil-1080x675.png',
      nome: 'QUALICORP ESTILO NACIONAL ADS I - A (EA)',
      operadora: 'AMIL SAÚDE - AMIL FÁCIL',
      valor: {
        total: 800.10,
        beneficiarios: [
          {
            id: '1',
            nome: 'DENISSON DOS SANTOS CARVALHO',
            tipo: 'Titular',
            valor: 400.05,
          },
          {
            id: '2',
            nome: '10/05/1994 - 25 ANOS',
            tipo: 'Dependente',
            valor: 400.05,
          },
        ],
      },
      abrangencia: 'NACIONAL',
      acomodacao: 'COLETIVA',
      coparticipacao: true,
    },
    {
      id: '4',
      logo:
          'https://groupsaude.com.br/wp-content/uploads/2019/11/Amil-1080x675.png',
      nome: 'QUALICORP ESTILO NACIONAL ADS - A (EA)',
      operadora: 'AMIL SAÚDE - AMIL FÁCIL',
      valor: {
        total: 700.10,
        beneficiarios: [
          {
            id: '1',
            nome: 'DENISSON DOS SANTOS CARVALHO',
            tipo: 'Titular',
            valor: 500.05,
          },
          {
            id: '2',
            nome: '10/05/1994 - 25 ANOS',
            tipo: 'Dependente',
            valor: 200.05,
          },
        ],
      },
      abrangencia: 'NACIONAL',
      acomodacao: 'COLETIVA',
      coparticipacao: true,
    },
    {
      id: '5',
      logo:
          'https://groupsaude.com.br/wp-content/uploads/2019/11/Amil-1080x675.png',
      nome: 'QUALICORP ABSOLUTO NACIONAL ADS I - A (EA)',
      operadora: 'AMIL SAÚDE - AMIL FÁCIL',
      valor: {
        total: 1000.50,
        beneficiarios: [
          {
            id: '1',
            nome: 'DENISSON DOS SANTOS CARVALHO',
            tipo: 'Titular',
            valor: 500.50,
          },
          {
            id: '2',
            nome: '10/05/1994 - 25 ANOS',
            tipo: 'Dependente',
            valor: 500.00,
          },
        ],
      },
      abrangencia: 'NACIONAL',
      acomodacao: 'COLETIVA',
      coparticipacao: true,
    },
    {
      id: '6',
      logo:
          'https://groupsaude.com.br/wp-content/uploads/2019/11/Amil-1080x675.png',
      nome: 'QUALICORP SUPERIOR NACIONAL ADS I - A (EA)',
      operadora: 'AMIL SAÚDE - AMIL FÁCIL',
      valor: {
        total: 1200.00,
        beneficiarios: [
          {
            id: '1',
            nome: 'DENISSON DOS SANTOS CARVALHO',
            tipo: 'Titular',
            valor: 1000.00,
          },
          {
            id: '2',
            nome: '10/05/1994 - 25 ANOS',
            tipo: 'Dependente',
            valor: 200.00,
          },
        ],
      },
      abrangencia: 'NACIONAL',
      acomodacao: 'COLETIVA',
      coparticipacao: true,
    },
  ];
};
