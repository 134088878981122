// eslint-disable-next-line import/no-extraneous-dependencies
import { Response } from 'miragejs';

export const obterVigenciaPorProposta = (schema, request) => {
  const { nrProposta } = JSON.parse(request.requestBody);

  return [
    {
      id: '1',
      dataVigencia: '15/06/2020',
      fechamentoAceitacao: '01/06/2020 23:59',
      fechamentoRepresentacao: '01/06/2020 23:59',
    },
    {
      id: '2',
      dataVigencia: '15/07/2020',
      fechamentoAceitacao: '18/06/2020 23:59',
      fechamentoRepresentacao: '01/06/2020 23:59',
    },
    {
      id: '3',
      dataVigencia: '01/12/2020',
      fechamentoAceitacao: '30/10/2020 23:59',
      fechamentoRepresentacao: '01/06/2020 23:59',
    },
    {
      id: '4',
      dataVigencia: '01/02/2021',
      fechamentoAceitacao: '28/01/2021 23:59',
      fechamentoRepresentacao: '01/06/2020 23:59',
    },
  ];
};
