import axios from 'axios';
import { Capacitor } from '@capacitor/core'
import apiCapacitor from './apiCapacitor';

const baseDomain = process.env.VUE_APP_BASE_URL;

let api;

if (Capacitor.isNativePlatform()) {
  api = apiCapacitor;
} else {
  api = axios.create({
    baseURL: baseDomain,
  });
}

export default api;
