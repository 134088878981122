<template>
  <ul class="q-lgpd-settings-tabs">
    <li
      v-for="(tab, index) in tabs"
      :key="index"
      class="q-lgpd-settings-tabs__item-wrapper"
    >
      <q-lgpd-settings-tabs-item
        class="q-lgpd-settings-tabs__item"
        :text="tab.text"
        :svgIcon="tab.svgIcon"
        :activated="index === tabIndex"
        @click="tabIndex = index"
      />
    </li>
  </ul>
</template>

<script>
// eslint-disable-next-line import/extensions
import QLgpdSettingsTabsItem from './components/TabsItem';

export default {
  components: {
    QLgpdSettingsTabsItem,
  },

  props: {
    value: {
      type: Number,
      default: null,
    },
    tabs: {
      type: Array,
      default: () => [],
      // model: { text, svgIcon }
    },
  },

  data() {
    return {
      tabIndex: null,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.tabIndex = value;
      },
    },

    tabIndex(value) {
      this.$emit('input', value);
    },

  },
};
</script>

<style lang="scss" scoped>
  @import "../../../../scss/_variables.scss";

  @media #{$breakpoint-desktop} {
    .q-lgpd-settings-tabs {
      margin: 0;
      padding: 0;
      border: 0;
      list-style: none;

      &__item-wrapper {
        margin-bottom: 16px;
      }
    }
  }

  @media #{$breakpoint-tablet} {
    .q-lgpd-settings-tabs {
      margin: 0;
      padding: 0;
      border: 0;
      list-style: none;

      &__item-wrapper {
        margin-bottom: 16px;
      }
    }
  }

  @media #{$breakpoint-mobile} {
    .q-lgpd-settings-tabs {
      margin: 0;
      display: flex;
      padding: 0;
      border: 0;
      list-style: none;

      &__item-wrapper {
        &:not(:first-child) {
          margin-left: 8px;
        }
      }
    }
  }

</style>
