<template>
  <div v-if="isLoading">
    <div id="loader-init-wrapper">
      <vue-topprogress ref="topProgress" :height="6"></vue-topprogress>
    </div>
  </div>
</template>

<script>
import vueTopprogress from "@/components/TopProgress.vue";

export default {
  components: {
    vueTopprogress,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  watch: {
    "$store.state.loadingInit": {
      deep: true,
      handler(value) {
        if (!value) {
          this.$refs.topProgress.done();
          setTimeout(() => {
            this.isLoading = false;
          }, 800);
        }
      },
    },
  },
  methods: {},
  mounted() {
    this.$refs.topProgress.start();
  },
};
</script>
<style lang="scss" scoped>
#loader-init-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffff;
  z-index: 1000;
}

.progress {
  z-index: 1001;
}
</style>
