import Vue from 'vue';
import Vuetify from 'vuetify';
import Fragment from 'vue-fragment';
import VueTheMask from 'vue-the-mask';
import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/vee-validate';
import api from './services/api';
import { makeServer } from './miragejs/server';
import './filters';

import './styles/global.scss';
import './validators';

import '@/plugins/vue-cropper';

// if (process.env.NODE_ENV === 'development') {
// makeServer();
// }
Vue.use(Vuetify);
Vue.use(VueTheMask);

Vue.use(Fragment.Plugin);

Vue.config.productionTip = false;
Vue.config.silent = process.env.VUE_APP_MOSTRAR_ALERTAS === "true";
Vue.prototype.$http = api;
Vue.prototype.$cleanObserver = (value) => JSON.parse(JSON.stringify(value));

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
