export default {
  SET_TRATAMENTOS(state, payload) {
    state.tratamentos = payload;
  },
  SET_INDICACAO(state, payload) {
    state.indicacao = payload;
  },
  SET_FILTROS(state, payload) {
    state.filtros = payload;
  },
  SET_STATUSLEADS(state, payload) {
    state.statusLeads = payload;
  },
  CLEAN_FILTER(state, payload) {
    state.filtros = {};
  },
  SET_VINCULO_PROPOSTA_LEAD(state, payload) {
    state.vinculoPropostaManual = payload;
  },
  SET_PLANTAOLEADS(state, payload) {
    state.plantaoLeads = payload;
  },
};
