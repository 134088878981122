import {
  corretorasPorCorretor, corretoras, estados, profissoes, entidades, entidadesPorProfissao, operadoraPorProposta, cidadePorEstado,
  grauParentesco,
} from './catalogo';
import {
  listaPorStatus, contador, contadorDetalhado, buscaCep, operadoraCongenere,
} from './propostas';
import { buscarSerasa } from './simulacao';
import { buscarCorretor, login } from './usuario';
import { planosPorProposta } from './planos';
import { obterVigenciaPorProposta } from './vigencia';
import { bancos } from './bancos';

export default function routes() {
  this.namespace = 'api';
  this.timing = 1500;

  this.get('catalogo/corretoras', corretoras);
  this.post('catalogo/corretoras', corretorasPorCorretor);

  this.get('catalogo/estados', estados);

  this.get('catalogo/entidades', entidades);
  this.post('catalogo/entidades', entidadesPorProfissao);

  this.get('catalogo/profissoes', profissoes);

  this.post('catalogo/operadora', operadoraPorProposta);

  this.post('catalogo/cidadePorEstado', cidadePorEstado);

  this.post('catalogo/grauParentesco', grauParentesco);

  this.post('propostas/listaPorStatus', listaPorStatus);
  this.post('propostas/contador', contador);
  this.post('propostas/contadorDetalhado', contadorDetalhado);
  this.post('propostas/buscaCep', buscaCep);
  this.post('propostas/operadoraCongenere', operadoraCongenere);

  this.post('simulacao/buscarSerasa', buscarSerasa);

  this.post('usuario/buscarCorretor', buscarCorretor);
  this.post('usuario/login', login);

  this.post('planos', planosPorProposta);

  this.post('vigencia', obterVigenciaPorProposta);

  this.post('bancos', bancos);
}
