const login = () => import('@/views/Login/index.vue');
const welcome = () => import('@/views/Welcome/index.vue');
const validacao = () => import('@/views/ValidacaoCorretor/index.vue');
const verificacao = () => import('@/views/VerificacaoMFA/index.vue');
const cadastro = () => import('@/views/CadastroCorretor/index.vue');
const notFound = () => import('@/views/NotFound/index.vue');

const areaLogada = {
  index: () => import('@/views/AreaLogada/index.vue'),
  home: () => import('@/views/AreaLogada/Home/index.vue'),
  propostas: () => import('@/views/AreaLogada/Propostas/index.vue'),
  leads: () => import('@/views/AreaLogada/Leads/Controle/index.vue'),
  leadDetalhe: () => import('@/views/AreaLogada/Leads/Controle/index.vue'),
  criarProposta: () => import('@/views/AreaLogada/CriarProposta/index.vue'),
  dashboard: () => import('@/views/AreaLogada/Dashboard/index.vue'),
  visaoGeral: () => import('@/views/AreaLogada/VisaoGeral/index.vue'),
  novoDependente: () => import('@/views/AreaLogada/CriarProposta/NovoDependente.vue'),
  dadosProposta: () => import('@/views/AreaLogada/DadosProposta/index.vue'),
  dadosPropostax: () => import('@/views/AreaLogada/DadosProposta/Home/index.vue'),
  meuPerfil: () => import('@/views/AreaLogada/MeuPerfil/index.vue'),
  confirmacaoDados: () => import('@/views/AreaLogada/ConfirmacaoDados/index.vue'),
  novaCorretora: () => import('@/views/AreaLogada/MeuPerfil/Corretoras/index.vue'),
  notificacoes: () => import('@/views/AreaLogada/Notificacoes/index.vue'),
  simulacoes: () => import('@/views/AreaLogada/Simulacoes/index.vue'),
  simulacaoResumo: () => import('@/views/AreaLogada/Simulacoes/Resumo.vue'),
  simulacaoCliente: () => import('@/views/AreaLogada/Simulacoes/SimulacaoCliente.vue'),
  criarSimulacao: () => import('@/views/AreaLogada/Simulacoes/Criar/index.vue'),
  vigencia: () => import('@/views/AreaLogada/Vigencia/index.vue'),
  plantaoLeads: () => import('@/views/AreaLogada/PlantaoLeads/index.vue'),
  leadsCalendario: () => import('@/views/AreaLogada/Leads/Calendario/index.vue'),
  classificacaoTamoJunto: () => import('@/views/AreaLogada/ClassificacaoTamoJunto/index.vue'),
  // swileBr: () => import('@/views/AreaLogada/SwileBr/index.vue'),
  niky: () => import('@/views/AreaLogada/Niky/index.vue'),
  envioBoleto: () => import('@/views/AreaLogada/EnvioBoleto/index.vue'),
  tabelasVenda: () => import('@/views/AreaLogada/TabelasVenda/index.vue'),
  historicoAlteracoes: () => import('@/views/AreaLogada/HistoricoAlteracao/index.vue')
};

const routes = [
  {
    path: '*',
    name: 'notFound',
    redirect: { name: 'login' },
  },
  {
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    path: '/bem-vindo',
    name: 'welcome',
    component: welcome,
  },
  {
    path: '/validacao',
    name: 'validacao',
    component: validacao,
  },
  {
    path: '/verificacao',
    name: 'verificacao',
    component: verificacao,
  },
  {
    path: '/cadastro',
    name: 'cadastro',
    component: cadastro,
    meta: {
      requireValidUser: true,
    },
  },
  {
    path: '/',
    component: areaLogada.index,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: '',
        name: 'areaLogada',
        component: areaLogada.home,
      },
      {
        path: 'meu-perfil',
        name: 'areaLogada.meuPerfil',
        component: areaLogada.meuPerfil,
      },
      {
        path: 'confirmacao-dados',
        name: 'areaLogada.confirmacaoDados',
        component: areaLogada.confirmacaoDados,
      },
      {
        path: 'nova-corretora',
        name: 'areaLogada.novaCorretora',
        component: areaLogada.novaCorretora,
      },
      {
        path: 'propostas',
        name: 'areaLogada.propostas',
        component: areaLogada.propostas,
      },
      {
        path: 'criar-proposta',
        name: 'areaLogada.criarProposta',
        component: areaLogada.criarProposta,
        children: [
          {
            path: 'dependente',
            name: 'areaLogada.criarProposta.dependente',
            component: areaLogada.novoDependente,
          },
        ],
      },
      {
        path: 'historico-alteracao',
        name: 'areaLogada.historicoAlteracoes',
        component: areaLogada.historicoAlteracoes
      },
      {
        path: 'leads',
        name: 'areaLogada.leads',
        component: areaLogada.leadsCalendario,
        children: [
          {
            path: 'calendario',
            name: 'areaLogada.leads.calendario',
            component: areaLogada.leadsCalendario,
          },
        ],
      },
      {
        path: 'visao-geral',
        name: 'areaLogada.visaoGeral',
        component: areaLogada.visaoGeral,
      },
      {
        path: 'dados-propostax',
        name: 'areaLogada.dadosPropostax',
        component: areaLogada.dadosPropostax,
      },
      {
        path: 'dados-proposta/:guid',
        name: 'areaLogada.dadosProposta',
        component: areaLogada.dadosProposta,
      },
      {
        path: 'lead',
        name: 'areaLogada.lead',
        component: areaLogada.leads,
      },
      {
        path: 'vigencia',
        name: 'areaLogada.vigencia',
        component: areaLogada.vigencia,
      },
      {
        path: 'lead/:codigo',
        name: 'areaLogada.leadDetalhe',
        component: areaLogada.leadDetalhe,
      },
      {
        path: 'lead/:codigo',
        name: 'areaLogada.leadCalendarioDetalhe',
        component: areaLogada.leadDetalhe,
      },
      {
        path: '/notificacoes',
        name: 'areaLogada.notificacoes',
        component: areaLogada.notificacoes,
      },
      {
        path: 'simulacoes',
        name: 'areaLogada.simulacoes',
        component: areaLogada.simulacoes,
      },
      {
        path: 'criar-simulacao',
        name: 'areaLogada.criarSimulacao',
        component: areaLogada.criarSimulacao,
      },
      {
        path: 'simulacoes/:guid',
        name: 'areaLogada.simulacaoResumo',
        component: areaLogada.simulacaoResumo,
      },
      {
        path: 'simulacao-cliente',
        name: 'areaLogada.simulacaoCliente',
        component: areaLogada.simulacaoCliente,
      },
      {
        path: 'plantao-leads',
        name: 'areaLogada.plantaoLeads',
        component: areaLogada.plantaoLeads,
      },
      {
        path: 'classificacao-tamo-junto',
        name: 'areaLogada.classificacaoTamoJunto',
        component: areaLogada.classificacaoTamoJunto,
      },
      // {
      //   path: 'swileBr',
      //   name: 'areaLogada.swileBr',
      //   component: areaLogada.swileBr,
      // },
      {
        path: 'niky',
        name: 'areaLogada.niky',
        component: areaLogada.niky,
      },
      {
        path: 'envio-boleto',
        name: 'areaLogada.envioBoleto',
        component: areaLogada.envioBoleto,
      },
      {
        path: 'tabelas-venda',
        name: 'areaLogada.tabelasVenda',
        component: areaLogada.tabelasVenda,
      },
    ],
  },
];

export default routes;
