export default {
  setGuid({ commit }, payload) {
    commit('SET_GUID', payload);
  },
  setTitular({ commit }, payload) {
    commit('SET_TITULAR', payload);
  },
  setAdministradora({ commit }, payload) {
    commit('SET_ADMINISTRADORA', payload);
  },
  setDependentes({ commit }, payload) {
    commit('SET_DEPENDENTES', payload);
  },
  setEndereco({ commit }, payload) {
    commit('SET_ENDERECO', payload);
  },
  setPlano({ commit }, payload) {
    commit('SET_PLANO', payload);
  },
  setAnotacao({ commit }, payload) {
    commit('SET_ANOTACAO', payload);
  },
  setEditando({ commit }, payload) {
    commit('SET_EDITANDO', payload);
  },
  resetDados({ commit }, payload) {
    commit('RESET_DADOS', payload);
  },
  setCorretora({ commit }, payload) {
    commit('SET_CORRETORA', payload)
  }
};
