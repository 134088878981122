var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "q-lgpd-button",
      style: _vm.styleMap,
      on: {
        click: function ($event) {
          return _vm.$emit(_vm.events.click)
        },
      },
    },
    [_c("div", { staticClass: "q-lgpd-button__layer" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }