var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: { css: false },
      on: {
        "before-enter": _vm.beforeEnter,
        enter: _vm.enter,
        "after-enter": _vm.afterEnter,
      },
    },
    [
      _vm.show
        ? _c("div", { staticClass: "top-progress", style: _vm.barStyle }, [
            _c("div", { staticClass: "peg", style: _vm.pegStyle }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }