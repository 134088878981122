// eslint-disable-next-line import/no-extraneous-dependencies
import { Response } from 'miragejs';

export const buscaCep = (schema, request) => {
  const { cep } = JSON.parse(request.requestBody);

  return {
    cep: '01001-000',
    logradouro: 'Praça da Sé',
    complemento: 'lado ímpar',
    bairro: 'Sé',
    localidade: 'São Paulo',
    uf: 'SP',
    unidade: '',
    ibge: '3550308',
    gia: '1004',
  };
};

export const obterProposta = (schema, request) => {
  const { nrProposta } = JSON.parse(request.requestBody);

  return {
    nrProposta: '12232323',
    dataCriacao: '30/04/2020 14:00',
    statusAgrupado: {
      valor: 'em_andamento',
      descricao: 'Em Andamento',
    },
    status: {
      valor: '1',
      descricao: 'Em Digitação',
    },
    etapaAtual: {
      valor: '1',
      descricao: 'Planos',
    },
    titular: {
      cpf: '43897537893',
      nome: 'Denisson dos Santos Carvalho da Carvalho',
      nascimento: '30/11/1994',
      idade: 25,
      profissao: 'Analista de Sistemas',
      email: 'denii.carvalho94@gmail.com',
      telefone: {
        id: '1',
        tipo: 'celular',
        numero: '11957930736',
      },
      telefoneAdicional: null,
      endereco: {
        cep: '05884070',
        estado: 'SP',
        cidade: 'São paulo',
        bairro: 'Jardim Florida',
        logradouro: 'Rua 23 de abril',
        numero: '1134',
        complemento: '',
      },
    },
    plano: {
      logo:
          'https://www.planodesaudesimulacao.com.br/wp-content/uploads/2018/07/logo-unimed-1.png',
      nome: 'Plano 1 Unimed',
      valor: {
        total: 400.10,
        beneficiarios: [
          {
            id: '1',
            nome: 'DENISSON DOS SANTOS CARVALHO',
            tipo: 'Titular',
            valor: 200.05,
          },
          {
            id: '2',
            nome: 'ANGIELI RODRIGUES',
            tipo: 'Dependente',
            valor: 200.05,
          },
        ],
      },
    },
    operadora: {
      id: '1',
      nome: 'Unimed Saúde',
    },
    entidade: {
      id: '1',
      nome: 'Estudantess de Escola e Universidades',
      sigla: 'UBE',
    },
    corretora: {
      id: '1',
      idSinf: '1',
      idFilial: '1',
      nomeFantasia: 'Angieli Rodrigues',
      portabilidade: true,
      supervisores: [
        { id: '', nome: '' },
      ],
    },
    vigencia: { id: '1', diasTransmissao: 10 },
    valorTotal: 583.0,
  };
};

export const contadorDetalhado = (schema, request) => {
  const { status } = JSON.parse(request.requestBody);

  switch (status) {
  case '0':
    return [];
  case '1':
    return [
      {
        quantidade: 15,
        valor: 7062.68,
        status: '104',
        nome: 'Proposta Assinada',
      },
      {
        quantidade: 23,
        valor: 3407.6399999999994,
        status: '100',
        nome: 'Em Digitação',
      },
      {
        quantidade: 28,
        valor: 14016.560000000001,
        status: '105',
        nome: 'DPS Enviada',
      },
      {
        quantidade: 4,
        valor: 2035.9199999999998,
        status: '101',
        nome: 'Proposta Enviada',
      },
    ];
  case '2':
    return [
      {
        quantidade: 5,
        valor: 2514.87,
        status: '104',
        nome: 'Reanalise',
      },
      {
        quantidade: 23,
        valor: 12367.269999999999,
        status: '201',
        nome: 'Em Análise',
      },
      {
        quantidade: 7,
        valor: 3200.55,
        status: '200',
        nome: 'Transmitida',
      },
      {
        quantidade: 4,
        valor: 2248.96,
        status: '206',
        nome: 'Fila de Transmissão',
      },
    ];
  case '3':
    return [
      {
        quantidade: 12,
        valor: 5673.82,
        status: '301',
        nome: 'Cancelado',
      },
      {
        quantidade: 5,
        valor: 2606.94,
        status: '201',
        nome: 'Implantada',
      },
    ];
  default:
    return new Response(400, {}, { errors: 'Status não encontrado' });
  }
};

export const operadoraCongenere = (schema, request) => {
  const { nrProposta } = JSON.parse(request.requestBody);

  return [
    { valor: '0', descricao: 'Nenhuma' },
    { valor: '1', descricao: 'Amil Saude' },
    { valor: '2', descricao: 'Sulamerica' },
    { valor: '3', descricao: 'Unimed' },
  ];
};

export const contador = (schema, request) => {
  const { status } = JSON.parse(request.requestBody);

  switch (status) {
  case '0':
    return {
      quantidade: 1,
      valor: 213.95,
      status: '0',
      nome: 'Simulação',
    };
  case '1':
    return {
      quantidade: 70,
      valor: 26522.800000000003,
      status: '1',
      nome: 'Em Andamento',
    };
  case '2':
    return {
      quantidade: 41,
      valor: 21180.429999999997,
      status: '2',
      nome: 'Em Aprovação',
    };
  case '3':
    return {
      quantidade: 17,
      valor: 8280.76,
      status: '3',
      nome: 'Histórico',
    };
  default:
    return new Response(404, {}, { errors: 'Status não encontrado' });
  }
};

export const listaPorStatus = (schema, request) => {
  const { statusAgrupado } = JSON.parse(request.requestBody);

  switch (statusAgrupado) {
  case 'em_andamento':
    return [
      {
        nrProposta: '',
        dataCriacao: '30/04/2020 14:00',
        statusAgrupado: {
          valor: 'em_andamento',
          descricao: 'Em Andamento',
        },
        status: {
          valor: '1',
          descricao: 'Em Digitação',
        },
        etapaAtual: {
          valor: '1',
          descricao: 'Dados Titular',
        },
        titular: {
          cpf: '43897537893',
          nome: 'Denisson dos Santos Carvalho da Carvalho',
          nascimento: '30/11/1994',
          idade: 25,
          profissao: 'Analista de Sistemas',
          email: 'denii.carvalho94@gmail.com',
          telefone: {
            id: '1',
            tipo: 'celular',
            numero: '11957930736',
          },
          telefoneAdicional: null,
          endereco: {
            cep: '05884070',
            estado: 'SP',
            cidade: 'São paulo',
            bairro: 'Jardim Florida',
            logradouro: 'Rua 23 de abril',
            numero: '1134',
            complemento: '',
          },
        },
        plano: {
          logo:
              'https://www.planodesaudesimulacao.com.br/wp-content/uploads/2018/07/logo-unimed-1.png',
          nome: 'Plano 1 Unimed',
          valor: 110.0,
        },
        operadora: {
          id: '1',
          nome: 'Unimed Saúde',
        },
        entidade: {
          id: '1',
          nome: 'Estudantess de Escola e Universidades',
          sigla: 'UBE',
        },
        vigencia: { id: '1', diasTransmissao: 10 },
        valorTotal: 583.0,
      },
      {
        nrProposta: '',
        dataCriacao: '30/04/2020 14:00',
        statusAgrupado: {
          id: 'em_andamento',
          descricao: 'Em Andamento',
        },
        status: {
          valor: '1',
          descricao: 'Em Digitação',
        },
        etapaAtual: {
          valor: '1',
          descricao: 'Seleção Operadora',
        },
        titular: {
          cpf: '43897537893',
          nome: 'Heloisa Carolinea da Cunha',
          nascimento: '30/11/1994',
          idade: 25,
          profissao: 'Analista de Sistemas',
          email: 'denii.carvalho94@gmail.com',
          telefone: {
            id: '1',
            tipo: 'celular',
            numero: '11957930736',
          },
          telefoneAdicional: null,
          endereco: {
            cep: '05884070',
            estado: 'SP',
            cidade: 'São paulo',
            bairro: 'Jardim Florida',
            logradouro: 'Rua 23 de abril',
            numero: '1134',
            complemento: '',
          },
        },
        plano: {
          logo:
              'https://www.planodesaudesimulacao.com.br/wp-content/uploads/2018/07/logo-unimed-1.png',
          nome: 'Plano 1 Unimed',
          valor: 110.0,
        },
        operadora: {
          id: '1',
          nome: 'Amil Saúde',
        },
        entidade: {
          id: '1',
          nome: 'Advogados',
          sigla: 'ABRACEM',
        },
        vigencia: { id: '1', diasTransmissao: 34 },
        valorTotal: 134.21,
      },
      {
        nrProposta: '',
        dataCriacao: '30/04/2020 14:00',
        statusAgrupado: {
          valor: 'em_andamento',
          descricao: 'Em Andamento',
        },
        status: {
          valor: '1',
          descricao: 'Em Digitação',
        },
        etapaAtual: {
          valor: '1',
          descricao: 'Associar Entidade',
        },
        titular: {
          cpf: '43897537893',
          nome: 'Mariane Nicole da Rocha',
          nascimento: '30/11/1994',
          idade: 25,
          profissao: 'Analista de Sistemas',
          email: 'denii.carvalho94@gmail.com',
          telefone: {
            id: '1',
            tipo: 'celular',
            numero: '11957930736',
          },
          telefoneAdicional: null,
          endereco: {
            cep: '05884070',
            estado: 'SP',
            cidade: 'São paulo',
            bairro: 'Jardim Florida',
            logradouro: 'Rua 23 de abril',
            numero: '1134',
            complemento: '',
          },
        },
        plano: {
          logo:
              'https://www.planodesaudesimulacao.com.br/wp-content/uploads/2018/07/logo-unimed-1.png',
          nome: 'Plano 1 Unimed',
          valor: 110.0,
        },
      },
      {
        nrProposta: '47342243',
        dataCriacao: '30/04/2020 14:00',
        statusAgrupado: {
          valor: 'em_andamento',
          descricao: 'Em Andamento',
        },
        status: {
          valor: '1',
          descricao: 'Proposta Enviada',
        },
        etapaAtual: {
          valor: '1',
          descricao: 'Assinar Proposta',
        },
        titular: {
          cpf: '43897537893',
          nome: 'Sandra Olivia Vieira',
          nascimento: '30/11/1994',
          idade: 25,
          profissao: 'Analista de Sistemas',
          email: 'denii.carvalho94@gmail.com',
          telefone: {
            id: '1',
            tipo: 'celular',
            numero: '11957930736',
          },
          telefoneAdicional: null,
          endereco: {
            cep: '05884070',
            estado: 'SP',
            cidade: 'São paulo',
            bairro: 'Jardim Florida',
            logradouro: 'Rua 23 de abril',
            numero: '1134',
            complemento: '',
          },
        },
        plano: {
          logo:
              'https://www.planodesaudesimulacao.com.br/wp-content/uploads/2018/07/logo-unimed-1.png',
          nome: 'Plano 1 Unimed',
          valor: 110.0,
        },
        operadora: {
          id: '1',
          nome: 'SulAmérica Saúde',
        },
        entidade: {
          id: '1',
          nome: 'Estudantess de Escola e Universidades',
          sigla: 'CAASP',
        },
        vigencia: { id: '1', diasTransmissao: 0 },
        valorTotal: 417.06,
      },
    ];
  case 'em_aprovacao':
    return [
      {
        nrProposta: '',
        dataCriacao: '30/04/2020 14:00',
        statusAgrupado: {
          valor: 'em_aprovacao',
          descricao: 'Em Aprovação',
        },
        status: {
          valor: '1',
          descricao: 'Em Digitação',
        },
        etapaAtual: {
          valor: '1',
          descricao: 'Dados Titular',
        },
        titular: {
          cpf: '43897537893',
          nome: 'Denisson dos Santos Carvalho da Carvalho',
          nascimento: '30/11/1994',
          idade: 25,
          profissao: 'Analista de Sistemas',
          email: 'denii.carvalho94@gmail.com',
          telefone: {
            id: '1',
            tipo: 'celular',
            numero: '11957930736',
          },
          telefoneAdicional: null,
          endereco: {
            cep: '05884070',
            estado: 'SP',
            cidade: 'São paulo',
            bairro: 'Jardim Florida',
            logradouro: 'Rua 23 de abril',
            numero: '1134',
            complemento: '',
          },
        },
        plano: {
          logo:
              'https://www.planodesaudesimulacao.com.br/wp-content/uploads/2018/07/logo-unimed-1.png',
          nome: 'Plano 1 Unimed',
          valor: 110.0,
        },
        operadora: {
          id: '1',
          nome: 'Unimed Saúde',
        },
        entidade: {
          id: '1',
          nome: 'Estudantess de Escola e Universidades',
          sigla: 'UBE',
        },
        vigencia: { id: '1', diasTransmissao: 10 },
        valorTotal: 583.0,
      },
    ];
  case 'historico':
    return [];
  default:
    return new Response(400, {}, { errors: 'Erro ao listar propostas' });
  }
};
