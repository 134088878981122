<template>
  <div class="q-lgpd-switch" :style="styleMap" @click="toggleValue">
    <span class="q-lgpd-switch__toggle"></span>
    <span class="q-lgpd-switch__label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    activatedLabel: {
      type: String,
      default: '',
    },
    deactivatedLabel: {
      type: String,
      default: '',
    },
    activatedColor: {
      type: String,
      default: '#2e9935',
    },
    deactivatedColor: {
      type: String,
      default: '#f00',
    },
  },

  data() {
    return {
      activated: false,
    };
  },

  computed: {
    styleMap() {
      return {
        '--toggle-color': this.activated ? this.activatedColor : this.deactivatedColor,
        '--toggle-position': this.activated ? 'var(--toogle-const-activated-pos)' : 'var(--toogle-const-deactivated-pos)',
      };
    },

    label() {
      return this.activated ? this.activatedLabel : this.deactivatedLabel;
    },
  },

  watch: {
    activated(value) {
      this.$emit('input', value);
    },

    value: {
      immediate: true,
      handler(value) {
        this.activated = value;
      },
    },
  },

  methods: {
    toggleValue() {
      this.activated = !this.activated;
    },
  },
};
</script>

<style lang="scss" scoped>
  .q-lgpd-switch {
    // const
    --toggle-const-width: 50px;
    --toggle-const-height: 30px;
    // computed
    --toogle-const-deactivated-pos: 2px;
    --toogle-const-activated-pos: calc(calc(var(--toggle-const-width) - var(--toggle-const-height)) + 2px);
    // handler
    --toggle-color: #f00;
    --toggle-position: var(--toogle-const-deactivated-pos);

    display: flex;
    cursor: pointer;

    &__toggle {
      width: var(--toggle-const-width);
      height: var(--toggle-const-height);

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: var(--toggle-color);
        border-radius: calc(var(--toggle-const-height) / 2);
        transition: background .3s;
      }

      &::after {
        content: '';
        display: block;
        width: calc(var(--toggle-const-height) - 4px);
        height: calc(var(--toggle-const-height) - 4px);
        margin-top: calc(calc(var(--toggle-const-height) - 2px) * -1);
        margin-left: var(--toggle-position);
        border-radius: 50%;
        background: white;
        transition: margin-left .3s;
      }
    }

    &__label {
      margin-left: 16px;
      color: var(--toggle-color);
      font-size: 22px;
      font-weight: bold;
    }
  }
</style>
