import planoService from '@/services/planoService';

import filters from '@/filters';
import { mapState, mapActions, mapGetters } from 'vuex';

export const getPlanoSimilar = {
  computed: {
    ...mapState({
      dependentesState: (state) => state.cadastroProposta.dependentes,
      propostaState: (state) => state.cadastroProposta.proposta,
      planoState: (state) => state.cadastroProposta.plano,
    }),
    ...mapGetters({
      planoAtual: 'cadastroProposta/planoAtual',
      dependentesAtual: 'cadastroProposta/dependentesAtual',
    }),
  },
  data() {
    return {
      novoPlano: null,
      confirmaAlteracao: false,
      dependentesAtuais: [],
    };
  },
  methods: {
    ...mapActions({
      setPlano: 'cadastroProposta/setPlano',
      setDadosProposta: 'cadastroProposta/setDadosProposta',
    }),
    async alterarPlano(plano) {
      this.setPlano({
        ...(this.planoState || {}),
        idProdutoFatura: plano.idProdutoFatura,
        nomePlanoAns: plano.nomePlanoAns,
        valorTotal: plano.valorTotal,
      });
      this.setDadosProposta({ ...this.propostaState });
    },
    async planoSimilar(acao, dependente, guid) {
      try {
        const enderecoIndex = this.propostaState.titular.enderecos.findIndex(
          (f) => f.tipo.findIndex((x) => x === 'ENDERECO_RESIDENCIAL') >= 0,
        );
        let enderecoResidencial = {};
        if (enderecoIndex >= 0) {
          enderecoResidencial = this.propostaState.titular.enderecos[
            enderecoIndex
          ];
        }

        const beneficiarios = [{ chave: this.propostaState.titular.nome, dataNascimento: this.propostaState.titular.dataNascimento }];
        if (acao === 'adicionar') {
          beneficiarios.push({ chave: dependente, dataNascimento: dependente });
        }

        if (acao === 'atualizar' && this.dependentesAtual) {
          if (this.dependentesAtual && this.dependentesAtual.length === 0) {
            beneficiarios.push({ chave: dependente, dataNascimento: dependente });
          }
        }

        this.dependentesAtual.forEach((d) => {
          if (acao === 'atualizar' && d.guid === guid) {
            beneficiarios.push({ chave: dependente, dataNascimento: dependente });
          } else {
            beneficiarios.push({ chave: d.dataNascimento, dataNascimento: d.dataNascimento });
          }
        });

        const data = await planoService.getPlanoSimilar({
          entidade: this.propostaState.titular.entidade,
          uf: enderecoResidencial.estado,
          cidade: enderecoResidencial.cidade,
          operadora: this.planoAtual.operadora,
          cpfCnpjCorretora: this.propostaState.corretora.cpfCnpj,
          beneficiarios,
          idProdutoFatura: this.planoAtual.idProdutoFatura,
          dataVigencia: this.propostaState.dataVigencia,
          administradora: this.propostaState.administradora,
          portabilidade: this.propostaState.possuiPortabilidade,
        });
        if (data.length === 0 || data === null) {
          this.novoPlano = false;
        } else if (data[0].valorTotal && this.planoAtual.valorTotal && (data[0].valorTotal.toString() !== this.planoAtual.valorTotal.toString())) {
          const resp = await this.$root.$confirmdialog.open(
            'Atenção',
            `Devido a alteração de informações, o valor do plano <strong>${data[0].nomeAmigavel}</strong> foi alterado de
            <strong>${this.$options.filters.currency(this.planoAtual.valorTotal)}</strong> para <strong>${this.$options.filters.currency(data[0].valorTotal)}</strong>`,
          );
          if (!resp) return;
          if (resp) await this.alterarPlano(data[0]);
          this.novoPlano = resp;
        } else if (data[0].valorTotal <= this.planoAtual.valorTotal) {
          await this.alterarPlano(data[0]);
          this.novoPlano = true;
        } else {
          this.novoPlano = true;
        }
      } catch (error) {
        // TODO: Implementar tratativa de erro
      } finally {
        this.plano = null;
      }
    },
    async planoAlterado(dataVigencia = null) {
      try {
        const enderecoIndex = this.propostaState.titular.enderecos.findIndex(
          (f) => f.tipo.findIndex((x) => x === 'ENDERECO_RESIDENCIAL') >= 0,
        );
        let enderecoResidencial = {};
        if (enderecoIndex >= 0) {
          enderecoResidencial = this.propostaState.titular.enderecos[
            enderecoIndex
          ];
        }
        const beneficiarios = [{ chave: this.propostaState.titular.nome, dataNascimento: this.propostaState.titular.dataNascimento }];

        this.dependentesState.forEach((d) => {
          beneficiarios.push({ chave: d.dataNascimento, dataNascimento: d.dataNascimento });
        });
        const data = await planoService.getPlanoSimilar({
          entidade: this.propostaState.titular.entidade,
          uf: enderecoResidencial.estado,
          cidade: enderecoResidencial.cidade,
          operadora: this.propostaState.plano.operadora,
          cpfCnpjCorretora: this.propostaState.corretora.cpfCnpj,
          beneficiarios,
          dataVigencia,
          idProdutoFatura: this.propostaState.plano.idProdutoFatura,
          administradora: this.propostaState.administradora,
          portabilidade: this.propostaState.possuiPortabilidade,
        });

        this.confirmaAlteracao = false;

        if (data.length === 0 || data === null) {
          this.confirmaAlteracao = false;
        } else if (data[0].valorTotal && this.planoAtual.valorTotal && (data[0].valorTotal.toString() !== this.planoAtual.valorTotal.toString())) {
          const resp = await this.$root.$confirmdialog.open(
            'Atenção',
            `Devido a alteração de informações, o valor do plano <strong>${data[0].nomeAmigavel}</strong> foi alterado de
            <strong>${this.$options.filters.currency(this.planoAtual.valorTotal)}</strong> para <strong>${this.$options.filters.currency(data[0].valorTotal)}</strong>`,
          );
          if (!resp) return;
          if (resp) await this.alterarPlano(data[0]);
          this.confirmaAlteracao = resp;
        } else if (data[0].valorTotal <= this.planoAtual.valorTotal) {
          await this.alterarPlano(data[0]);
          this.confirmaAlteracao = true;
        } else {
          this.confirmaAlteracao = true;
        }
      } catch (error) {
        // TODO: Implementar tratativa de erro
      } finally {
        this.plano = null;
      }
    },
  },
};
