import filters from "@/filters";

function validate(value) {
  if (value) {
    const v = filters.removeSymbols(value);
    return v.length === 11;
  }
  return true;
}

export default validate;
