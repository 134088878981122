<template>
  <q-lgpd-settings-contents-base
    class="q-lgpd-settings-contents-privacity"
    :title="computedTitle"
    @click-save="$emit('click-save')"
  >
    <slot>
      <p>Ao permitir esses cookies, você consegue armazenar suas preferências. Assim, você não precisa inserir seus dados mais de uma vez em um formulário, por exemplo.</p>
    </slot>

    <q-lgpd-switch
      v-model="enabled"
      :activated-color="switchColors.activated"
      :deactivated-color="switchColors.deactivated"
      activated-label="Habilitado"
      deactivated-label="Desabilitado"
    />

    <q-lgpd-settings-cookie-table v-if="showCookieTable" :cookies="cookies"/>

  </q-lgpd-settings-contents-base>
</template>

<script>
// eslint-disable-next-line import/extensions
import QLgpdSwitch from '../../../../../Switch';
// eslint-disable-next-line import/extensions
import QLgpdSettingsCookieTable from '../../../CookieTable';
// eslint-disable-next-line import/extensions
import QLgpdSettingsContentsBase from '../Base';
// eslint-disable-next-line import/extensions
import DefaultTitles from '../../../../constants/DefaultTitles.js';

export default {
  components: {
    QLgpdSwitch,
    QLgpdSettingsCookieTable,
    QLgpdSettingsContentsBase,
  },

  props: {
    title: String,
    cookies: {
      type: Array,
      default: () => [],
      // model: {name: 'cookie1', source: 'Qualicorp', type: 'Essencial', duration: '365 dias' }
    },
    switchColors: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      enabled: true,
    };
  },

  watch: {
    enabled: {
      immediate: true,
      handler(value) {
        this.$emit('update-enabled', value);
      },
    },
  },

  computed: {
    computedTitle() {
      return this.title || DefaultTitles.FUNCIONALITY;
    },

    showCookieTable() {
      return (this.cookies && this.cookies.length);
    },
  },
};
</script>
