<template>
  <div class="autoheight" :style="`min-height:${height}px`">
    <slot></slot>
  </div>
</template>

<script>

export default {
  data() {
    return {
      height: window.innerHeight,
    };
  },
  methods: {
    getHeight() {
      this.height = window.innerHeight;
    },
  },
  mounted() {
    window.addEventListener('resize', this.getHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.getHeight);
  },
};
</script>

<style scoped lang="scss">
.autoheight {
  display: flex;
  height: 100%;
}
</style>
