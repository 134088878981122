<template>
  <div class="q-lgpd-settings-cookie-table">
    <div class="q-lgpd-settings-cookie-table__btn-details" @click="toggleDetailVisibility"> {{ btnDetailsText }} </div>

    <table v-show="showTable" class="q-lgpd-settings-cookie-table__table">
      <thead>
        <tr>
          <th scope="col">Cookie</th>
          <th scope="col">Origem</th>
          <th scope="col">Tipo</th>
          <th scope="col">Duração</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(cookie, index) in enabledCookies" :key="index">
          <td aria-label="Cookie">{{ cookie.name }}</td>
          <td aria-label="Origem">{{ cookie.source }}</td>
          <td aria-label="Tipo"> {{ cookie.type }} </td>
          <td aria-label="Duração">{{ cookie.duration }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    cookies: {
      type: Array,
      default: () => [],
      // model: {name: 'cookie1', source: 'Qualicorp', type: 'Essencial', duration: '365 dias' }
    },
  },

  data() {
    return {
      showTable: false,
    };
  },

  computed: {
    btnDetailsText() {
      return this.showTable ? 'OCULTAR DETALHES -' : 'MOSTRAR DETALHES +';
    },
    enabledCookies() {
      return this.cookies.filter((el) => !el.disabled);
    },
  },

  methods: {
    toggleDetailVisibility() {
      this.showTable = !this.showTable;
    },
  },

};
</script>

<style lang="scss" scoped>
  @import "../../../../scss/_variables.scss";

  @media #{$breakpoint-desktop} {
    .q-lgpd-settings-cookie-table {
      &__btn-details {
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: bold;
        color: var(--window-content-cookie-table-btn-color);
        cursor: pointer;
      }

      &__table {
        width: 100%;
        border-spacing: 0;

        & th {
          padding: 8px;
          text-align: left;
          background: var(--window-content-cookie-table-header-color);
          color: var(--window-content-cookie-table-header-text-color);
          font-size: 18px;
        }

        & td {
          padding: 8px;
          background: var(--window-content-cookie-table-color);
          color: var(--window-content-cookie-table-text-color);
          font-size: 18px;
        }
      }
    }
  }

  @media #{$breakpoint-tablet} {
    .q-lgpd-settings-cookie-table {
      &__btn-details {
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: bold;
        color: var(--window-content-cookie-table-btn-color);
        cursor: pointer;
      }

      &__table {
        width: 100%;
        border-spacing: 0;

        & th {
          padding: 8px;
          text-align: left;
          background: var(--window-content-cookie-table-header-color);
          color: var(--window-content-cookie-table-header-text-color);
          font-size: 18px;
        }

        & td {
          padding: 8px;
          background: var(--window-content-cookie-table-color);
          color: var(--window-content-cookie-table-text-color);
          font-size: 18px;
        }
      }
    }
  }

  @media #{$breakpoint-mobile} {
    .q-lgpd-settings-cookie-table {
      &__btn-details {
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: bold;
        color: var(--window-content-cookie-table-btn-color);
        cursor: pointer;
      }

      &__table {
        width: 100%;
        border-spacing: 0;

        & th {
          padding: 0 16px;
          text-align: left;
          background: var(--window-content-cookie-table-header-color);
          color: var(--window-content-cookie-table-header-text-color);
          font-size: 18px;
        }

        & td {
          padding: 0 16px;
          background: var(--window-content-cookie-table-color);
          color: var(--window-content-cookie-table-text-color);
          font-size: 18px;
        }
      }
    }
  }

</style>
