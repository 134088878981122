var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        id: "alerta",
        "retain-focus": false,
        persistent: "",
        width: "290",
      },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.cancel.apply(null, arguments)
        },
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "size-16" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("v-card-text", {
            staticClass: "size-14 line-height",
            domProps: { innerHTML: _vm._s(_vm.message) },
          }),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "pa-1" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-test-id": "recusar-alerta",
                    color: `${_vm.options.cancelButtonColor} darken-1`,
                    text: "",
                  },
                  on: { click: _vm.cancel },
                },
                [_vm._v(_vm._s(_vm.options.cancelButton))]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-test-id": "aceitar-alerta",
                    color: `${_vm.options.confirmButtonColor} darken-1`,
                    text: "",
                  },
                  on: { click: _vm.agree },
                },
                [_vm._v(_vm._s(_vm.options.confirmButton))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }