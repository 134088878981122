<template>
  <div class="q-lgpd-settings-contents-base">
    <div class="q-lgpd-settings-contents-base__title">{{ title }}</div>
    <div class="q-lgpd-settings-contents-base__main">
      <slot></slot>
    </div>
    <q-lgpd-settings-footer class="q-lgpd-settings-contents-base__footer" @click-save="$emit(events.clickSave)"/>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import QLgpdSettingsFooter from "../../../Footer";

export default {
  components: {
    QLgpdSettingsFooter,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      events: {
        clickSave: 'click-save',
      },
    };
  },

};
</script>

<style lang="scss">
  @import "../../../../../../scss/_variables.scss";

  @media #{$breakpoint-desktop} {
    .q-lgpd-settings-contents-base {
      &__main, &__main * {
        font-size: 20px;
        color: var(--window-content-texts-color);
      }
    }
  }
  @media #{$breakpoint-tablet} {
    .q-lgpd-settings-contents-base {
      &__main, &__main * {
        font-size: 18px;
        color: var(--window-content-texts-color);

      }
    }
  }
  @media #{$breakpoint-mobile} {
    .q-lgpd-settings-contents-base {
      &__main, &__main * {
        font-size: 18px;
        color: var(--window-content-texts-color);
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import "../../../../../../scss/_variables.scss";

  @media #{$breakpoint-desktop} {
    .q-lgpd-settings-contents-base {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      padding: 30px;

      &__title {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 24px;
        color: var(--window-content-titles-color);
      }

      &__main {
        overflow-y: auto;
        flex-grow: 1;
      }

      &__footer {
        margin-top: auto;
      }
    }
  }
  @media #{$breakpoint-tablet} {
    .q-lgpd-settings-contents-base {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      padding: 26px;

      &__title {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 18px;
        color: var(--window-content-titles-color)
      }

      &__main {
        overflow-y: auto;
        flex-grow: 1;
      }

      &__footer {
        margin-top: auto;
      }
    }
  }
  @media #{$breakpoint-mobile} {
    .q-lgpd-settings-contents-base {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      padding: 24px;
      contain: strict;

      &__title {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 14px;
        color: var(--window-content-titles-color)
      }

      &__main {
        overflow-y: auto;
        flex-grow: 1;
      }

      &__footer {
        margin-top: auto;
      }
    }
  }

</style>
