var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-lgpd-settings-contents-base",
    {
      staticClass: "q-lgpd-settings-contents-privacity",
      attrs: { title: _vm.computedTitle },
      on: {
        "click-save": function ($event) {
          return _vm.$emit("click-save")
        },
      },
    },
    [
      _vm._t("default", function () {
        return [
          _c("p", [
            _vm._v(
              " Estes cookies permitem que o site forneça mais funcionalidade e personalização. Podem ser definidos por nós ou por fornecedores terceiros cujos serviços adicionamos às nossas páginas. O objetivo destes cookies é fornecer-lhe uma experiência mais personalizada em nosso site com base nas suas visitas e seleções anteriores. Se não permitir estes cookies, todos ou alguns destes serviços poderão não funcionar corretamente. "
            ),
          ]),
          _c("p", [
            _vm._v("Mais informações, acesso o nosso "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://qualicorp.com.br/avisodeprivacidade/",
                  target: "_blank",
                },
              },
              [_vm._v("Aviso de Privacidade")]
            ),
            _vm._v("."),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }