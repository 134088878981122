import api from '@/services/api';
import usuarioService from '@/services/usuarioService';
import { Capacitor } from '@capacitor/core'

export default {
  async login({ commit, dispatch }, payload) {
    const { username, password } = payload;

    const data = await usuarioService.login({
      email: username, senha: password,
    });
    return data;
  },

  logout({ commit, dispatch }) {
    if (!Capacitor.isNativePlatform()) {
      delete api.defaults.headers.common.auth;
    }
    // chamar commit depois da exclusao do token do localstorage
    commit('LOGOUT');
    dispatch('user/logout', null, { root: true });
    dispatch('cadastroProposta/logout', null, { root: true });
    localStorage.removeItem(`${process.env.VUE_APP_NAME_AUTH}`);
  },
};
