// eslint-disable-next-line import/no-extraneous-dependencies
import { Response } from 'miragejs';

export const operadoraPorProposta = (schema, request) => {
  const { nrProposta } = JSON.parse(request.requestBody);

  if (!nrProposta) {
    return new Response(400, {}, { errors: 'Numero da proposta é obrigatorio' });
  }

  return [
    {
      id: '1',
      sigla: 'CNU',
      nome: '(NOVO PORTIFÓLIO)',
    },
    {
      id: '2',
      sigla: 'AMIL SAÚDE',
      nome: 'AMIL FÁCIL',
    },
    {
      id: '3',
      sigla: 'AMIL SAÚDE',
      nome: 'AMIL FÁCIO (NEXT)',
    },
  ];
};

export const grauParentesco = (schema, request) => {
  const { nrProposta } = JSON.parse(request.requestBody);

  if (!nrProposta) {
    return new Response(400, {}, { errors: 'Numero da proposta é obrigatorio' });
  }

  return [
    {
      valor: '1',
      descricao: 'FILHO(A)',
    },
    {
      valor: '2',
      descricao: 'IRMÃO(Ã)',
    },
    {
      valor: '3',
      descricao: 'PAI/MÃE',
    },
    {
      valor: '4',
      descricao: 'NETO',
    },
    {
      valor: '5',
      descricao: 'SOBRINHO(A)',
    },
    {
      valor: '6',
      descricao: 'TUTELADO',
    },
  ];
};

export const entidadesPorProfissao = (schema, request) => {
  const { publicoAlvo } = JSON.parse(request.requestBody);

  if (!publicoAlvo) {
    return new Response(400, {}, { errors: 'profissão é obrigatorio' });
  }

  return {
    id: '1',
    sigla: 'UBE',
    nome: 'UNIAO BRASILEIRA DOS ESTUDANTES ',
  };
};

export const entidades = (schema, request) => ([
  {
    id: '1',
    sigla: 'UBE',
    nome: 'UNIAO BRASILEIRA DOS ESTUDANTES ',
  },
  {
    id: '2',
    sigla: 'SAESP',
    nome: 'SINDICATO DOS ADMINISTRADORES NO ESTADO DE SAO PAULO',
  },
]);

export const profissoes = (schema, request) => ([
  { id: '1', nome: 'Administrador' },
  { id: '2', nome: 'Administradores' },
  { id: '3', nome: 'Administradores escolares' },
  { id: '4', nome: 'Advogado' },
  { id: '5', nome: 'Advogados' },
  { id: '6', nome: 'Analista de Sistemas' },
  { id: '7', nome: 'Arquiteto' },
  { id: '8', nome: 'Arquitetos' },
  { id: '9', nome: 'Assistentes Sociais' },
  { id: '10', nome: 'Atuários' },
  { id: '11', nome: 'Auditores Fiscais de Tributos' },
  { id: '12', nome: 'Auxiliar' },
  { id: '13', nome: 'Auxiliares de disciplina' },
  { id: '14', nome: 'Bacharel em Direito' },
  { id: '15', nome: 'Bacharéis Matemáticos' },
  { id: '16', nome: 'Bacharéis em Direito' },
  { id: '17', nome: 'Bacharéis em Sistema da Informática' },
  { id: '18', nome: 'Biomédico' },
  { id: '19', nome: 'Biomédicos' },
  { id: '20', nome: 'Biólogos' },
  { id: '21', nome: 'Contabilista' },
  { id: '22', nome: 'Contadores' },
  { id: '23', nome: 'Corretor' },
  { id: '24', nome: 'Defensor Público' },
  { id: '25', nome: 'Defensores Públicos' },
  { id: '26', nome: 'Delegado da Polícia Estadual' },
  { id: '27', nome: 'Delegado de Polícia Federal' },
  { id: '28', nome: 'Delegados da Polícia Civil' },
  { id: '29', nome: 'Delegados da Polícia Estadual' },
  { id: '30', nome: 'Dentista' },
]);

export const estados = (schema, request) => ([
  { sigla: 'GO', nome: 'Goiás' },
  { sigla: 'MT', nome: 'Mato Grosso' },
  { sigla: 'MS', nome: 'Mato Grosso do Sul' },
  { sigla: 'DF', nome: 'Distrito Federal' },
  { sigla: 'AM', nome: 'Amazonas' },
  { sigla: 'AC', nome: 'Acre' },
  { sigla: 'RO', nome: 'Rondônia' },
  { sigla: 'RR', nome: 'Roraima' },
  { sigla: 'AP', nome: 'Amapá' },
  { sigla: 'TO', nome: 'Tocantins' },
  { sigla: 'PA', nome: 'Para' },
  { sigla: 'MA', nome: 'Maranhão' },
  { sigla: 'PI', nome: 'Piaui' },
  { sigla: 'CE', nome: 'Ceara' },
  { sigla: 'RN', nome: 'Rio Grande do Norte' },
  { sigla: 'PB', nome: 'Paraiba' },
  { sigla: 'PE', nome: 'Pernambuco' },
  { sigla: 'SE', nome: 'Sergipe' },
  { sigla: 'AL', nome: 'Alagoas' },
  { sigla: 'BA', nome: 'Bahia' },
  { sigla: 'SP', nome: 'São Paulo' },
  { sigla: 'MG', nome: 'Minas Gerais' },
  { sigla: 'RJ', nome: 'Rio de Janeiro' },
  { sigla: 'ES', nome: 'Espirito Santo' },
  { sigla: 'PR', nome: 'Parana' },
  { sigla: 'SC', nome: 'Santa Catarina' },
  { sigla: 'RS', nome: 'Rio Grande do Sul' },
]);

export const cidadePorEstado = (schema, request) => {
  const { estado } = JSON.parse(request.requestBody);

  return [
    { nome: 'São Paulo' },
    { nome: 'Sorocaba' },
    { nome: 'Campinas' },
    { nome: 'Piracicaba' },
  ];
};

export const corretoras = (schema, request) => [
  {
    id: '1',
    idSinf: '1',
    idFilial: '1',
    nomeFantasia: 'JRB Corretora',
    corretores: [
      {
        id: '1',
        nome: 'Angieli Rodrigues',
        cpf: '54547852022',
        contato: {
          id: '1',
          email: 'armiranda@qualicorp.com.br',
          telefones: [
            {
              id: '1',
              tipo: 'celular',
              numero: '11957930736',
              preferencial: true,
            },
          ],
        },
        dataNascimento: '30/11/1990',
        tipoCorretor: {
          valor: '1',
          descricao: 'Externo',
        },
        endereco: {
          id: '1',
          cep: '05884070',
          uf: 'SP',
          cidade: 'São Paulo',
          bairro: 'Jd do Estado',
          logradouro: 'Rua ivaldo',
          numero: '36',
          complemento: '',
        },
      },
    ],
  },
  {
    id: '2',
    idSinf: '2',
    idFilial: '2',
    nomeFantasia: 'RS Solucoes',
    corretores: [],
  },
  {
    id: '3',
    idSinf: '3',
    idFilial: '3',
    nomeFantasia: 'SDF',
    corretores: [],
  },
  {
    id: '4',
    idSinf: '4',
    idFilial: '4',
    nomeFantasia: 'Corretora Teste',
    corretores: [],
  },
  {
    id: '5',
    idSinf: '5',
    idFilial: '5',
    nomeFantasia: 'Franquia Repetida',
    corretores: [],
  },
  {
    id: '6',
    idSinf: '6',
    idFilial: '6',
    nomeFantasia: 'Plaven',
    corretores: [],
  },
  {
    id: '7',
    idSinf: '7',
    idFilial: '7',
    nomeFantasia: 'Corretora Angariação',
    corretores: [],
  },
  {
    id: '8',
    idSinf: '8',
    idFilial: '8',
    nomeFantasia: 'Nikit Corretora',
    corretores: [],
  },
  {
    id: '9',
    idSinf: '9',
    idFilial: '9',
    nomeFantasia: 'Vital Andava',
    corretores: [],
  },
  {
    id: '10',
    idSinf: '10',
    idFilial: '10',
    nomeFantasia: 'Teste',
    corretores: [],
  },
];
export const corretorasPorCorretor = (schema, request) => {
  const { cpf } = JSON.parse(request.requestBody);

  if (!cpf) {
    return new Response(400, {}, { errors: 'CPF é obrigatorio' });
  }

  switch (cpf) {
  case '54547852022': // interno ja cadastrado
    return [
      {
        id: '1',
        idSinf: '1',
        idFilial: '1',
        nomeFantasia: 'Angieli Rodrigues',
        portabilidade: true,
      },
    ];
  case '64350194010': // externo ja cadastrado
    return [
      {
        id: '1',
        idSinf: '3',
        idFilial: '4',
        nomeFantasia: 'JRB Corretora',
      },
      {
        id: '2',
        idSinf: '2',
        idFilial: '3',
        nomeFantasia: 'Brasil Seg Corretora',
        portabilidade: true,
      },
    ];
  case '36726459076': // interno novo
    return [{
      id: '2',
      idSinf: '2',
      idFilial: '2',
      nomeFantasia: 'Márcio Ponte',
    }];
  case '00255200080': // externo novo
    return {};
  default:
    return new Response(400, {}, { errors: 'Erro ao consultar corretoras' });
  }
};
