export default {
  logout({ commit }) {
    commit('LOGOUT');
  },
  setUserCpf({ commit }, payload) {
    commit('SET_CPF', payload);
  },
  setUserData({ commit }, payload) {
    commit('SET_DATA', payload);
  },
  setUrlFotoPerfil({ commit }, payload) {
    commit('SET_URL_FOTO_PERFIL', payload);
  },
  setCheckIsMobile({ commit }, payload) {
    commit('CHECK_MOBILE', payload);
  },
};
